// Chakra Imports
import {
  Avatar,
  Badge,
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
// Custom Components
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
// Assets
import { useAuth } from "contexts/AuthContext";
import { IoMdArrowDropdown } from "react-icons/io";
import routes from "routes";
import { useGetAllTenants } from "hooks/useGetAllTenants";
import { Role } from "types/graphql-types";
import useLastSync from "hooks/useLastSync";
import { format, parse } from "date-fns";
import { useSyncDataContext } from "providers/SyncProvider";


export default function HeaderLinks({ secondary = false }: { secondary: boolean }) {
  const { logout } = useAuth();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const { user } = useAuth();
  const { tenants } = useGetAllTenants();
  const { lastSync } = useLastSync();
  const { isSyncing } = useSyncDataContext();

  function changeTenant(key: string): void {
    localStorage.setItem("tenantId", key);
    window.location.reload();
  }

  const formatLastSyncDate = (date: string) => {
    const parsedDate = parse(date, "MM/dd/yyyy, hh:mm:ss a", new Date());
    const formattedTime = format(parsedDate, "hh:mm a");
    return formattedTime;
  }

  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      <SidebarResponsive routes={routes as RoutesType[]} />
      {(!isSyncing && lastSync) && <Text fontSize="sm">Last sync {formatLastSyncDate(lastSync?.toLocaleString())}</Text>}
      { isSyncing && <Text fontSize="sm">Syncing...</Text>}
      { user?.profile?.adminLevel === Role.Superadmin && <Menu>
        <MenuButton p="0px">
          <Box display="flex" alignItems="center">
            <Badge colorScheme="gray" mr="10px">{user?.tenantId}</Badge>
            <Icon
              me="10px"
              h="18px"
              w="18px"
              color={navbarIcon}
              as={IoMdArrowDropdown}
            />
          </Box>
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          {tenants?.map((tenant) => (
            <Flex key={tenant?.key} flexDirection="column" p="10px">
              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                color="red.400"
                borderRadius="8px"
                px="14px"
                onClick={() => changeTenant(tenant?.key)}
              >
                <Text fontSize="sm">{tenant?.key}</Text>
              </MenuItem>
            </Flex>
          ))}
          </MenuList>
        </Menu>
      }
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: "pointer" }}
            color="white"
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
            name={user?.profile?.displayName}
            src={user?.profile?.profilePhotoUrl}
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Hey, {user?.profile?.displayName}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              color="red.400"
              borderRadius="8px"
              px="14px"
              onClick={() => logout()}
            >
              <Text fontSize="sm">Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
