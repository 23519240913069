import { ApolloCache, DefaultContext, FetchResult, MutationFunctionOptions } from "@apollo/client";
import { BULK_CREATE_CODE_MUTATION } from "graphql/code.graphql";
import { BulkCreateCodeInput, Code } from "types/graphql-types";
import { useTenantMutation } from "./useTenantQuery";


type IAddCode = {
  bulkCreateCode: (options?: MutationFunctionOptions<
    { bulkCreateCode: Code[] },
    { bulkCreateCodeInput: BulkCreateCodeInput },
    DefaultContext,
    ApolloCache<any>
  >) => Promise<FetchResult<{ bulkCreateCode: Code[] }>>;
  loading: boolean;
}

export const useBulkCreateCode = (): IAddCode => {
  const [bulkCreateCode, { loading }] = useTenantMutation<{ bulkCreateCode: Code[] }, { bulkCreateCodeInput: BulkCreateCodeInput }>(BULK_CREATE_CODE_MUTATION);

  return { bulkCreateCode, loading };
};