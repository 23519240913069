import { GET_TENANT_ANALYTICS_DATA } from "graphql/data.graphql";
import { TenantAnalyticsDto } from "types/graphql-types";
import { useTenantQuery } from "./useTenantQuery";

interface IStudentsData {
  data: { tenantsAnalytics: TenantAnalyticsDto };
  loading: boolean;
}

export const useTenantAnalyicsData = (): IStudentsData => {
  return useTenantQuery(GET_TENANT_ANALYTICS_DATA);
};