import { BellIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button, Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Text,
  useToast,
  VStack,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useAuth } from "contexts/AuthContext";
import { useActivityIndicator } from "hooks/useActivityIndicator";
import { useMentorsData } from "hooks/useMentorsData";
import { useSchedulePushNotifications } from "hooks/useSchedulePushNotifications";
import { useStudentsData } from "hooks/useStudentsData";
import { useEffect, useState } from "react";
import { AccountDto, Role } from "types/graphql-types";
;

export default function EngagementPage() {
  const [audience, setAudience] = useState<string>(Role.Student)
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const toast = useToast();
  const { user } = useAuth();
  const { schedulePushNotifications } = useSchedulePushNotifications();
  const [allUsers, setAllUsers] = useState<AccountDto[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<AccountDto[]>([]);
  const { data: mentorsData, loading: mentorsLoading } = useMentorsData()
  const { data: studentsData, loading: studentsLoading } = useStudentsData()
  const { setIsLoading } = useActivityIndicator();

  useEffect(() => {
    if (audience === Role.Mentor) {
      setAllUsers(mentorsData?.getMentors ?? [])
    } else {
      setAllUsers(studentsData?.getStudents ?? [])
    }
    setSelectedUsers([]);
  }, [audience, mentorsData?.getMentors, studentsData?.getStudents])

  useEffect(() => {
    setIsLoading([mentorsLoading, studentsLoading].some(loading => loading))
  }, [mentorsLoading, setIsLoading, studentsLoading])

  const handleSend = () => {
    try {
      schedulePushNotifications({ 
        variables: { 
          body: message,
          title: title, 
          userType: audience as Role.Mentor | Role.Student, 
          tenantId: user?.tenantId,
          tokens: selectedUsers.map(user => user.notificationTokens.FCM)
        } 
      });
      toast({
        title: "Notification Sent",
        description: "Your push notification has been sent successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "An error occurred while sending the push notification.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const toggleUserSelection = (user: AccountDto) => {
    setSelectedUsers(prev => 
      prev.some(u => u.id === user.id)
        ? prev.filter(u => u.id !== user.id)
        : [...prev, user]
    );
  };

  return (
    <Box
      maxWidth="2xl"
      margin="auto"
      pt={{ base: "130px", md: "80px", xl: "80px" }}
    >
      <Card>
        <Container maxW="container.xl" py={8}>
          <Flex direction={{ base: "column", md: "row" }} gap={8}>
            <Box flex={1}>
              <Heading mb={6}>Send Push Notification</Heading>
              <VStack spacing={4} align="stretch">
                <FormControl>
                  <FormLabel>Audience</FormLabel>
                  <Select
                    placeholder="Select audience"
                    value={audience}
                    onChange={(e) => setAudience(e.target.value)}
                  >
                    <option value={Role.Student}>Students</option>
                    <option value={Role.Mentor}>Mentors</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Notification Title</FormLabel>
                  <Input
                    placeholder="Enter title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Notification Message</FormLabel>
                  <Input
                    placeholder="Enter message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Recipients</FormLabel>
                  <Wrap spacing={2}>
                    {allUsers.map((user) => (
                      <WrapItem key={user.id}>
                        <HStack
                          bg={selectedUsers.some(u => u.id === user.id) ? "blue.100" : "gray.100"}
                          borderRadius="full"
                          px={2}
                          py={1}
                          spacing={2}
                          cursor="pointer"
                          onClick={() => toggleUserSelection(user)}
                        >
                          <Avatar size="xs" src={user?.profile?.photoURL} name={user?.profile?.displayName} />
                          <Text fontSize="sm">{user?.profile?.displayName}</Text>
                        </HStack>
                      </WrapItem>
                    ))}
                  </Wrap>
                </FormControl>
                <Button
                  colorScheme="blue"
                  leftIcon={<BellIcon />}
                  onClick={handleSend}
                  isDisabled={!title || !message || selectedUsers.length === 0}
                >
                  Send Notification
                </Button>
              </VStack>
            </Box>
            <Box flex={1}>
              <Heading mb={6}>Preview</Heading>
              <Box
                borderWidth={2}
                borderRadius="lg"
                p={4}
                maxW="300px"
                mx="auto"
                bg="gray.100"
              >
                <Box
                  borderWidth={2}
                  borderRadius="md"
                  p={2}
                  bg="white"
                  boxShadow="md"
                >
                  <Flex align="center" mb={2}>
                    <PhoneIcon mr={2} />
                    <Text fontWeight="bold">Device Preview</Text>
                  </Flex>
                  <Box
                    borderWidth={1}
                    borderRadius="sm"
                    p={3}
                    bg="gray.50"
                    minH="100px"
                  >
                    {title || message ? (
                      <>
                        <Text fontWeight="bold">
                          {title || "Notification Title"}
                        </Text>
                        <Text>
                          {message || "Notification message will appear here"}
                        </Text>
                      </>
                    ) : (
                      <Text color="gray.500">
                        Enter a title and message to preview the notification
                      </Text>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Container>
      </Card>
    </Box>
  );
}