import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function shouldSync() {
  const lastSync = localStorage.getItem('lastSync');
  return lastSync ? new Date(lastSync).getTime() + 1000 * 60 * 5 < Date.now() : true;
}
