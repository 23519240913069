import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** Mongo object id scalar type */
  ObjectId: { input: any; output: any; }
};

export type AccountConnetionsPopulated = {
  __typename?: 'AccountConnetionsPopulated';
  /** The ID of the account. */
  connectedWith?: Maybe<AccountDto>;
  /** The ID of the account. */
  connectionType?: Maybe<ConnectionTypes>;
};

export type AccountDto = {
  __typename?: 'AccountDTO';
  /** The ID of the account. */
  _id?: Maybe<Scalars['ObjectId']['output']>;
  accountConnections?: Maybe<Array<ConnectionDto>>;
  /** The calls associated with the account. */
  availability?: Maybe<Array<AvailabilityDto>>;
  /** The calls associated with the account. */
  calls?: Maybe<Array<CallDto>>;
  /** The code of the account. */
  code?: Maybe<Code>;
  /** The creation date of the account. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The last update date of the account. */
  disabledAt?: Maybe<Scalars['String']['output']>;
  /** The ID of the account. */
  id?: Maybe<Scalars['ObjectId']['output']>;
  /** Student journey */
  journey?: Maybe<AccountJourneyDto>;
  /** The ID of the student Mentor. */
  mentorId?: Maybe<Scalars['ObjectId']['output']>;
  /** The notification tokens associated with the account. */
  notificationTokens?: Maybe<NotificationTokensDto>;
  /** The onboarding status of the account. */
  onboardingStatus?: Maybe<OnboardingStatusDto>;
  /** The profile of the account. */
  profile: AccountProfileDto;
  /** The quiz response of the account. */
  quizResponse?: Maybe<QuizResponse>;
  /** Mentor rating. */
  rating?: Maybe<Scalars['Int']['output']>;
  /** The SB account. */
  sendBirdUser?: Maybe<SendBirdUserDto>;
  /** The ID of the tenant associated with the account. */
  tenantId?: Maybe<Scalars['String']['output']>;
  /** The last update date of the account. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the user associated with the account. */
  userId: Scalars['ObjectId']['output'];
  /** User type */
  userType?: Maybe<Scalars['String']['output']>;
};

export type AccountInput = {
  /** The connections associated with the account. */
  accountConnections?: InputMaybe<Array<ConnectionInputDto>>;
  /** The calls associated with the account. */
  availability?: InputMaybe<Array<AvailabilityInputDto>>;
  /** The calls associated with the account. */
  calls?: InputMaybe<Array<CallInput>>;
  /** The code of the account. */
  code?: InputMaybe<CreateCodeInput>;
  /** The creation date of the account. */
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Student journey */
  journey?: InputMaybe<AccountJourneyInputDto>;
  /** The ID of the student Mentor. */
  mentorId?: InputMaybe<Scalars['ObjectId']['input']>;
  /** The notification tokens associated with the account. */
  notificationTokens?: InputMaybe<NotificationTokensInput>;
  /** The onboarding status of the account. */
  onboardingStatus?: InputMaybe<OnboardingStatusInput>;
  /** The profile information of the account. */
  profile?: InputMaybe<AccountProfileInput>;
  /** The quiz response of the account. */
  quizResponse?: InputMaybe<QuizResponseInput>;
  /** Mentor rating. */
  rating?: InputMaybe<Scalars['Int']['input']>;
  /** The SB account. */
  sendBirdUser?: InputMaybe<InputSendBirdUserDto>;
  /** The ID of the tenant associated with the account. */
  tenantId?: InputMaybe<Scalars['String']['input']>;
  /** The last update date of the account. */
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The ID of the user associated with the account. */
  userId?: InputMaybe<Scalars['ObjectId']['input']>;
  /** User type */
  userType?: InputMaybe<Scalars['String']['input']>;
};

export type AccountJourneyDto = {
  __typename?: 'AccountJourneyDTO';
  /** Student callsTitlesTopics of journey */
  callsTitlesTopics?: Maybe<Array<CallTitleTopic>>;
  /** Input type for Student prizesPlacement journey */
  prizesPlacement?: Maybe<Array<PrizePlacement>>;
};

export type AccountJourneyInputDto = {
  /** Input type for Student callsTitlesTopics journey */
  callsTitlesTopics?: InputMaybe<Array<CallTitleTopicInput>>;
  /** Input type for Student prizesPlacement journey */
  prizesPlacement?: InputMaybe<Array<PrizePlacementInput>>;
};

export type AccountLinkDto = {
  __typename?: 'AccountLinkDTO';
  /** Account Object */
  account?: Maybe<AccountDto>;
  /** Unique identifier for the account link */
  accountId: Scalars['ObjectId']['output'];
  /** Tenant Identifier */
  tenantKey: Scalars['String']['output'];
  /** Unique identifier for the account link */
  userId: Scalars['ObjectId']['output'];
};

export type AccountLinkInput = {
  /** Unique identifier for the account link */
  accountId: Scalars['ObjectId']['input'];
  /** Tenant Identifier */
  tenantKey: Scalars['String']['input'];
  /** Unique identifier for the account link */
  userId: Scalars['ObjectId']['input'];
};

export type AccountLocationDto = {
  __typename?: 'AccountLocationDTO';
  /** The latitude of the account location */
  latitude: Scalars['Float']['output'];
  /** The longitude of the account location */
  longitude: Scalars['Float']['output'];
};

export type AccountLocationInput = {
  /** The latitude of the account location */
  latitude: Scalars['Float']['input'];
  /** The longitude of the account location */
  longitude: Scalars['Float']['input'];
};

export type AccountProfileDto = {
  __typename?: 'AccountProfileDTO';
  /** Mentor College. */
  college?: Maybe<Scalars['String']['output']>;
  /** Profile description. Current just applicable for Mentors */
  description?: Maybe<Scalars['String']['output']>;
  /** The display name of the account profile. */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Profile Ethnicity */
  ethnicity?: Maybe<Scalars['String']['output']>;
  /** The grade level of the account profile. */
  gradeLevel?: Maybe<Scalars['String']['output']>;
  /** Mentor highSchool of the account profile. */
  highSchool?: Maybe<Scalars['String']['output']>;
  /** The intended major of the account profile. */
  intendedMajor?: Maybe<Scalars['String']['output']>;
  /** Mentor profile interests. */
  interests?: Maybe<Array<Scalars['String']['output']>>;
  /** The location of the account profile. */
  location?: Maybe<AccountLocationDto>;
  /** Mentor Major. */
  major?: Maybe<Scalars['String']['output']>;
  /** The graduation year of the account profile. Just applicable for Mentors. */
  majorYear?: Maybe<Scalars['String']['output']>;
  /** Mentor Minor. */
  minor?: Maybe<Scalars['String']['output']>;
  /** [DEPRECATED] Onboarding Quiz object. */
  onboardingQuiz: QuizDto;
  /** The Phone Number of the account profile. */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** The URL of the account profile photo. */
  photoURL?: Maybe<Scalars['String']['output']>;
  /** Videos Collection. Currently just available for Mentors. */
  videos?: Maybe<Array<VideoDto>>;
};

export type AccountProfileInput = {
  /** Mentor College. */
  college?: InputMaybe<Scalars['String']['input']>;
  /** Profile description. Current just applicable for Mentors */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The display name of the account profile. */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Profile Ethnicity */
  ethnicity?: InputMaybe<Scalars['String']['input']>;
  /** The grade level of the account profile. */
  gradeLevel?: InputMaybe<Scalars['String']['input']>;
  /** Mentor highSchool of the account profile. */
  highSchool?: InputMaybe<Scalars['String']['input']>;
  /** The intended major of the account profile. */
  intendedMajor?: InputMaybe<Scalars['String']['input']>;
  /** Mentor profile interests. */
  interests?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The location of the account profile. */
  location?: InputMaybe<AccountLocationInput>;
  /** Mentor Major. */
  major?: InputMaybe<Scalars['String']['input']>;
  /** The graduation year of the account profile. Just applicable for Mentors. */
  majorYear?: InputMaybe<Scalars['String']['input']>;
  /** Mentor Minor. */
  minor?: InputMaybe<Scalars['String']['input']>;
  /** The onboarding status for the account. */
  onboardingQuiz?: InputMaybe<QuizInputDto>;
  /** The Phone Number of the account profile. */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** The photo URL of the account profile. */
  photoURL?: InputMaybe<Scalars['String']['input']>;
  /** Videos Collection. Currently just available for Mentors. */
  videos?: InputMaybe<Array<VideoInputDto>>;
};

export type AdminAuthPayload = {
  __typename?: 'AdminAuthPayload';
  /** The access token for the authenticated user */
  accessToken: Scalars['String']['output'];
  /** The admin object without the password field */
  admin: AdminDto;
};

export type AdminDto = {
  __typename?: 'AdminDTO';
  /** User ID */
  _id: Scalars['ObjectId']['output'];
  /** Authenticated User access token */
  accessToken?: Maybe<Scalars['String']['output']>;
  /** Admin level */
  adminLevel: Scalars['String']['output'];
  /** Timestamp of when the user was created */
  createdAt: Scalars['String']['output'];
  /** Admin dsiplayName field */
  displayName: Scalars['String']['output'];
  /** Email field */
  email: Scalars['String']['output'];
  /** Admin Password */
  password: Scalars['String']['output'];
  /** Admin profile photo url */
  profilePhotoUrl: Scalars['String']['output'];
  /** Admin Tenant */
  tenantId: Scalars['String']['output'];
  /** Timestamp of the last update to the user */
  updatedAt: Scalars['String']['output'];
};

export type AdminInputDto = {
  /** Admin level */
  adminLevel: Scalars['String']['input'];
  /** Admin dsiplayName field */
  displayName: Scalars['String']['input'];
  /** Email field */
  email: Scalars['String']['input'];
  /** Admin Password */
  password: Scalars['String']['input'];
  /** Admin profile photo url */
  profilePhotoUrl: Scalars['String']['input'];
  /** Admin Tenant */
  tenantId?: InputMaybe<Scalars['String']['input']>;
};

export type AnalyticsReportDto = {
  __typename?: 'AnalyticsReportDTO';
  documents: Scalars['Int']['output'];
  sucess: Scalars['Boolean']['output'];
};

export type Answer = {
  __typename?: 'Answer';
  /** The answer of the question. */
  answer: Array<Scalars['String']['output']>;
  /** The category of the question. */
  category: Scalars['String']['output'];
  /** The question of the answer. */
  question: Scalars['String']['output'];
  /** The type of the question. */
  questionType: Scalars['String']['output'];
  /** The weight of the question. */
  weight: Scalars['Float']['output'];
};

export type AnswerInput = {
  /** The answer of the question. */
  answer: Array<Scalars['String']['input']>;
  /** The category of the question. */
  category: Scalars['String']['input'];
  /** The question of the answer. */
  question: Scalars['String']['input'];
  /** The type of the question. */
  questionType: Scalars['String']['input'];
  /** The weight of the question. */
  weight: Scalars['Float']['input'];
};

export type AuthInput = {
  /** The Firebase client token */
  token: Scalars['String']['input'];
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  /** The access token for the authenticated user */
  accessToken: Scalars['String']['output'];
  /** The user object without the password field */
  user: UserDto;
};

export type AvailabilityDto = {
  __typename?: 'AvailabilityDTO';
  day: Scalars['String']['output'];
  slots: Array<SlotDto>;
};

export type AvailabilityInputDto = {
  day: Scalars['String']['input'];
  slots: Array<SlotInputDto>;
};

export type BulkCreateCodeInput = {
  amount: Scalars['Int']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  forceConnectionWith?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  used?: Scalars['Boolean']['input'];
};

export type CallDto = {
  __typename?: 'CallDTO';
  /** The ID of the call */
  accountId: Scalars['ObjectId']['output'];
  /** Indicates if the call lasts all day */
  allDay?: Maybe<Scalars['Boolean']['output']>;
  /** Details of the partner involved in the call */
  attendees: Array<AccountDto>;
  /** Mentor Collage associated with the call */
  college?: Maybe<Scalars['String']['output']>;
  /** The date and time of the call. */
  dateTime: Scalars['DateTime']['output'];
  /** Default time zone of the call */
  defaultTz?: Maybe<Scalars['String']['output']>;
  /** Call details */
  details?: Maybe<CallDetailsDto>;
  /** Indicates if the call is done. */
  done?: Maybe<Scalars['Boolean']['output']>;
  /** Duration of the call in minutes */
  duration?: Maybe<Scalars['Float']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  /** High school associated with the call */
  highSchool?: Maybe<Scalars['String']['output']>;
  /** User ID */
  id: Scalars['ObjectId']['output'];
  /** Note */
  note?: Maybe<Scalars['String']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Call Status */
  status?: Maybe<Scalars['String']['output']>;
  /** The title of the call. */
  title?: Maybe<Scalars['String']['output']>;
  /** The topics discussed in the call. */
  topics?: Maybe<Array<Scalars['String']['output']>>;
};

export type CallDetailsDto = {
  __typename?: 'CallDetailsDto';
  /** Call number */
  id?: Maybe<Scalars['String']['output']>;
  /** Call title */
  title?: Maybe<Scalars['String']['output']>;
  /** Call topics */
  topics?: Maybe<Array<Scalars['String']['output']>>;
};

export type CallDetailsInputDto = {
  /** Call number */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Call title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Call topics */
  topics?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CallInput = {
  /** Account who owns the call */
  accountId: Scalars['ObjectId']['input'];
  /** Indicates if the call lasts all day */
  allDay?: InputMaybe<Scalars['Boolean']['input']>;
  /** Details of the partner involved in the call */
  attendees: Array<AccountInput>;
  /** Mentor Collage associated with the call */
  college?: InputMaybe<Scalars['String']['input']>;
  /** The date and time of the call. */
  dateTime: Scalars['DateTime']['input'];
  /** Default time zone of the call */
  defaultTz?: InputMaybe<Scalars['String']['input']>;
  /** Call details */
  details: CallDetailsInputDto;
  /** Indicates if the call is done. */
  done?: InputMaybe<Scalars['Boolean']['input']>;
  /** Duration of the call in minutes */
  duration?: InputMaybe<Scalars['Float']['input']>;
  endedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** High school associated with the call */
  highSchool?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the call */
  id?: InputMaybe<Scalars['ObjectId']['input']>;
  /** Note */
  note?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The title of the call. */
  title?: InputMaybe<Scalars['String']['input']>;
  /** The topics discussed in the call. */
  topics?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CallProposalDto = {
  __typename?: 'CallProposalDTO';
  /** Aceptance status of the proposal */
  accepted?: Maybe<Scalars['Boolean']['output']>;
  /** ID of who is sending the proposal */
  applicantId: Scalars['ObjectId']['output'];
  /** Call ID */
  callId: Scalars['ObjectId']['output'];
  /** New Call date of the proposal */
  dateTime?: Maybe<Scalars['DateTime']['output']>;
  /** Due date of the proposal */
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  /** Proposal ID */
  id?: Maybe<Scalars['ObjectId']['output']>;
  /** Proposal note */
  note?: Maybe<Scalars['String']['output']>;
  /** ID of who is required to accept the proposal */
  requiredId: Scalars['ObjectId']['output'];
};

export type CallProposalInputDto = {
  /** ID of who is sending the proposal */
  applicantId: Scalars['String']['input'];
  /** Call ID */
  callId: Scalars['String']['input'];
  /** New Call date of the proposal */
  dateTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Due date of the proposal */
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Proposal ID */
  id?: InputMaybe<Scalars['String']['input']>;
  /** Proposal note */
  note?: InputMaybe<Scalars['String']['input']>;
  /** ID of who is required to accept the proposal */
  requiredId: Scalars['String']['input'];
};

export type CallTitleTopic = {
  __typename?: 'CallTitleTopic';
  call?: Maybe<Scalars['String']['output']>;
  front?: Maybe<Front>;
  id: Scalars['Int']['output'];
  videos?: Maybe<Array<VideoDto>>;
};

export type CallTitleTopicInput = {
  call?: InputMaybe<Scalars['String']['input']>;
  front?: InputMaybe<FrontInput>;
  id: Scalars['Int']['input'];
  videos?: InputMaybe<Array<VideoInputDto>>;
};

export type CallsListDto = {
  __typename?: 'CallsListDTO';
  /** List of all calls */
  all?: Maybe<Array<CallDto>>;
  /** List of canceled calls */
  canceled?: Maybe<Array<CallDto>>;
  /** List of done calls */
  done?: Maybe<Array<CallDto>>;
  /** List of calls for the month */
  month?: Maybe<Array<CallDto>>;
  /** List of no-show calls */
  noShow?: Maybe<Array<CallDto>>;
  /** List of past calls */
  past?: Maybe<Array<CallDto>>;
  /** List of rejected calls */
  rejected?: Maybe<Array<CallDto>>;
  /** List of calls for today */
  today?: Maybe<Array<CallDto>>;
  /** List of calls for tomorrow */
  tomorrow?: Maybe<Array<CallDto>>;
  /** List of upcoming calls */
  upcoming?: Maybe<Array<CallDto>>;
  /** List of calls for the week */
  week?: Maybe<Array<CallDto>>;
};

export type Code = {
  __typename?: 'Code';
  code?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  forceConnectionWith?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  used: Scalars['Boolean']['output'];
};

export type CodePaginatedResponse = {
  __typename?: 'CodePaginatedResponse';
  data: Array<Code>;
  metadata: PaginationMetadata;
};

export type CommonApplication = {
  __typename?: 'CommonApplication';
  link: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type CommonApplicationInput = {
  link: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type ConnectionDto = {
  __typename?: 'ConnectionDTO';
  connectedWith?: Maybe<AccountDto>;
  connectionType: Scalars['String']['output'];
};

export type ConnectionInputDto = {
  connectedWith: Scalars['ObjectId']['input'];
  connectionType: ConnectionTypes;
};

/** The ConnectionTypes of a Conection */
export enum ConnectionTypes {
  FriendOf = 'FRIEND_OF',
  ManagedBy = 'MANAGED_BY',
  Managing = 'MANAGING',
  MentoredBy = 'MENTORED_BY',
  Mentoring = 'MENTORING'
}

export type CreateCallInput = {
  /** The ID of the call */
  accountId: Scalars['String']['input'];
  /** Indicates if the call lasts all day */
  allDay?: InputMaybe<Scalars['Boolean']['input']>;
  /** Details of the partner involved in the call */
  attendees: Array<Scalars['ObjectId']['input']>;
  /** Mentor Collage associated with the call */
  college?: InputMaybe<Scalars['String']['input']>;
  /** The date and time of the call. */
  dateTime: Scalars['DateTime']['input'];
  /** Default time zone of the call */
  defaultTz?: InputMaybe<Scalars['String']['input']>;
  /** Call details */
  details: CallDetailsInputDto;
  /** Indicates if the call is done. */
  done?: InputMaybe<Scalars['Boolean']['input']>;
  /** Duration of the call in minutes */
  duration?: InputMaybe<Scalars['Float']['input']>;
  endedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** High school associated with the call */
  highSchool?: InputMaybe<Scalars['String']['input']>;
  /** Note */
  note?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Call Status */
  status?: InputMaybe<Scalars['String']['input']>;
  /** The topics discussed in the call. */
  topics?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateCodeInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  forceConnectionWith?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  used?: Scalars['Boolean']['input'];
};

export type CreateMetricDto = {
  metadata: Scalars['JSON']['input'];
  metrics: Scalars['JSON']['input'];
  timestamp: Scalars['DateTime']['input'];
};

export type CreateUniversityInput = {
  brandingDescription: Scalars['String']['input'];
  coatOfArms: Scalars['String']['input'];
  commonApplication: CommonApplicationInput;
  description: Scalars['String']['input'];
  events: Array<EventInput>;
  latestNews: Array<NewsInput>;
  logo: Scalars['String']['input'];
  name: Scalars['String']['input'];
  profileImage: Scalars['String']['input'];
  videos: Array<VideoInputDto>;
};

export type CreateUserInput = {
  /** The ID of the user. */
  _id?: InputMaybe<Scalars['String']['input']>;
  /** Authenticated User access token */
  accessToken?: InputMaybe<Scalars['String']['input']>;
  /** The accounts associated with the user. */
  accounts?: Array<AccountInput>;
  /** The accounts associated with the user. */
  accountsLinked?: Array<AccountLinkInput>;
  /** User signup code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** The email address of the user. */
  email: Scalars['String']['input'];
  /** Indicates whether the email of the user has been verified. */
  emailVerified?: Scalars['Boolean']['input'];
  /** The Firebase UIDs associated with the user. */
  firebaseUid?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The name of the user. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The password of the user. */
  password: Scalars['String']['input'];
  /** Indicates whether the user has changed their password. */
  passwordChanged?: Scalars['Boolean']['input'];
  /** Indicates whether the user has requested a password reset. */
  passwordReset?: Scalars['Boolean']['input'];
  /** User type string */
  type?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user. */
  username: Scalars['String']['input'];
};

export type CurrentAccountDto = {
  __typename?: 'CurrentAccountDTO';
  /** The connections associated with the account. */
  accountConnections?: Maybe<Array<AccountConnetionsPopulated>>;
  /** The calls associated with the account. */
  availability?: Maybe<Array<AvailabilityDto>>;
  /** The calls associated with the account. */
  calls?: Maybe<Array<CallDto>>;
  /** The creation date of the account. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The last update date of the account. */
  disabledAt?: Maybe<Scalars['String']['output']>;
  /** The ID of the account. */
  id?: Maybe<Scalars['ObjectId']['output']>;
  /** Student journey */
  journey?: Maybe<AccountJourneyDto>;
  /** The ID of the student Mentor. */
  mentorId?: Maybe<Scalars['ObjectId']['output']>;
  /** The notification tokens associated with the account. */
  notificationTokens?: Maybe<NotificationTokensDto>;
  /** The onboarding status of the account. */
  onboardingStatus?: Maybe<OnboardingStatusDto>;
  /** The profile of the account. */
  profile?: Maybe<AccountProfileDto>;
  /** Mentor rating. */
  rating?: Maybe<Scalars['Int']['output']>;
  /** The SB account. */
  sendBirdUser?: Maybe<SendBirdUserDto>;
  /** The ID of the tenant associated with the account. */
  tenantId?: Maybe<Scalars['String']['output']>;
  /** The last update date of the account. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the user associated with the account. */
  userId?: Maybe<Scalars['ObjectId']['output']>;
  /** User type */
  userType?: Maybe<Scalars['String']['output']>;
};

export type Event = {
  __typename?: 'Event';
  dateTime: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  linkURL: Scalars['String']['output'];
  location: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type EventInput = {
  dateTime: Scalars['String']['input'];
  id: Scalars['String']['input'];
  imageUrl: Scalars['String']['input'];
  linkURL: Scalars['String']['input'];
  location: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type FilterMetricDto = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Front = {
  __typename?: 'Front';
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  topics?: Maybe<Array<Topic>>;
};

export type FrontInput = {
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  topics?: InputMaybe<Array<TopicInput>>;
};

export type InputSendBirdUserDto = {
  /** Access token for the SB account. */
  accessToken?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the SB account. */
  userId: Scalars['String']['input'];
};

export type LoginInput = {
  /** The username of the user */
  identifier: Scalars['String']['input'];
  /** The password of the user */
  password: Scalars['String']['input'];
};

export type MeDto = {
  __typename?: 'MeDTO';
  /** Authenticated User access token */
  accessToken?: Maybe<Scalars['String']['output']>;
  /** List of associated accounts */
  accountsLinked: Array<AccountLinkDto>;
  /** Timestamp of when the user was created */
  createdAt: Scalars['String']['output'];
  currentAccount?: Maybe<CurrentAccountDto>;
  /** User email address */
  email: Scalars['String']['output'];
  /** Indicates whether the email is verified */
  emailVerified: Scalars['Boolean']['output'];
  /** Timestamp of when the email was verified */
  emailVerifiedAt: Scalars['String']['output'];
  /** List of Firebase UIDs associated with the user */
  firebaseUid?: Maybe<Array<Scalars['String']['output']>>;
  /** User ID */
  id: Scalars['ObjectId']['output'];
  /** User full name */
  name?: Maybe<Scalars['String']['output']>;
  /** User password (hashed) */
  password: Scalars['String']['output'];
  /** Indicates whether the password has been changed */
  passwordChanged: Scalars['Boolean']['output'];
  /** Timestamp of when the password was last changed */
  passwordChangedAt: Scalars['String']['output'];
  /** Indicates whether the password has been reset */
  passwordReset: Scalars['Boolean']['output'];
  /** Timestamp of when the password was last reset */
  passwordResetAt: Scalars['String']['output'];
  /** User type */
  type: Scalars['String']['output'];
  /** Timestamp of the last update to the user */
  updatedAt: Scalars['String']['output'];
  /** Username chosen by the user */
  username?: Maybe<Scalars['String']['output']>;
};

export type MentorsAnalyticsDto = {
  __typename?: 'MentorsAnalyticsDTO';
  /** The ID of the mentor. */
  accountId?: Maybe<Scalars['ObjectId']['output']>;
  /** The name of the mentor. */
  displayName?: Maybe<Scalars['String']['output']>;
  /** The email of the mentor. */
  email?: Maybe<Scalars['String']['output']>;
  /** Last activity date of the mentor. */
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  /** Last data sync date of the mentor. */
  lastCallAt?: Maybe<Scalars['DateTime']['output']>;
  /** Last data sync date of the mentor. */
  lastSyncAt?: Maybe<Scalars['DateTime']['output']>;
  /** The email of the mentor. */
  major?: Maybe<Scalars['String']['output']>;
  /** The number of calls the mentor has made. */
  meanProgressions: Scalars['Int']['output'];
  /** The photoUrl of the mentor. */
  photoUrl?: Maybe<Scalars['String']['output']>;
  /** The status of the mentor. */
  status: Scalars['String']['output'];
  /** The number of calls the mentor has made. */
  totalDoneCalls: Scalars['Int']['output'];
  totalMentees: Scalars['Int']['output'];
  /** The number of calls the mentor has made. */
  totalUpcomingCalls: Scalars['Int']['output'];
  /** The type of the analytics. */
  type?: Maybe<Scalars['String']['output']>;
  /** The ID of the mentor. */
  userId?: Maybe<Scalars['ObjectId']['output']>;
};

export type Metrics = {
  __typename?: 'Metrics';
  metadata: Scalars['JSON']['output'];
  metrics: Scalars['JSON']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptProposal: CallProposalDto;
  addConnection: AccountDto;
  /** Login with username and password */
  adminSigningIn: AdminAuthPayload;
  bulkCreateCode: Array<Code>;
  cancelCall: CallDto;
  /** Create a new account. */
  createAccount: AccountDto;
  /** Create account with token */
  createAccountWithToken: AuthPayload;
  /** Create a new Admin for a Tenant */
  createAdmin: AdminDto;
  createCall: CallDto;
  createCallProposal: CallProposalDto;
  createCode: Code;
  createMetric: Metrics;
  createTenant: TenantDto;
  /** Create a new user */
  createUser: UserDto;
  deleteCode: Scalars['Boolean']['output'];
  /** Delete a user */
  deleteUser: UserDto;
  /** Delete a user by account ID */
  deleteUserByAccountId: UserDto;
  endCall: CallDto;
  /** Login with username and password */
  login: AuthPayload;
  /** Login with Google Firebase Provider */
  loginWithFirebase: AuthPayload;
  /** Logout the user */
  logout: Scalars['Boolean']['output'];
  /** Matching function. */
  matching: CurrentAccountDto;
  rejectProposal: CallProposalDto;
  /** Removing account. */
  removeAccount: AccountDto;
  removeAllConnectionsFromAccount: AccountDto;
  removeCall: CallDto;
  removeConnection: AccountDto;
  /** Update the password of the user */
  resetMyPassword: ResetPasswordResponse;
  /** Schedule a push notification */
  schedulePushNotification: Scalars['Boolean']['output'];
  /** Schedule a push notification to a tenant ID */
  scheduleTenantIdPushNotification: Scalars['Boolean']['output'];
  /** Send a push notification */
  sendPushNotification: Scalars['Boolean']['output'];
  /** Setting FCM and APNS tokens for notifications. */
  setFCM: AccountDto;
  startCall: CallDto;
  transferConnection: AccountDto;
  /** Update an existing account. */
  updateAccount: AccountDto;
  updateCall: CallDto;
  /** Update the password of the user */
  updatePassword: Scalars['Boolean']['output'];
  /** Update the password of the user */
  updatePasswordWithCode: Scalars['Boolean']['output'];
  updateTenant: TenantDto;
  /** Update a user */
  updateUser: UserDto;
};


export type MutationAcceptProposalArgs = {
  input: Scalars['String']['input'];
};


export type MutationAddConnectionArgs = {
  accountId: Scalars['String']['input'];
  connectionId: Scalars['String']['input'];
};


export type MutationAdminSigningInArgs = {
  input: LoginInput;
};


export type MutationBulkCreateCodeArgs = {
  bulkCreateCodeInput: BulkCreateCodeInput;
};


export type MutationCancelCallArgs = {
  id: Scalars['String']['input'];
};


export type MutationCreateAccountArgs = {
  accountData: AccountInput;
};


export type MutationCreateAccountWithTokenArgs = {
  authData: AuthInput;
  code: Scalars['String']['input'];
  tenantKey: Scalars['String']['input'];
};


export type MutationCreateAdminArgs = {
  input: AdminInputDto;
};


export type MutationCreateCallArgs = {
  input: CreateCallInput;
};


export type MutationCreateCallProposalArgs = {
  input: CallProposalInputDto;
};


export type MutationCreateCodeArgs = {
  createCodeInput: CreateCodeInput;
};


export type MutationCreateMetricArgs = {
  createMetricDto: CreateMetricDto;
};


export type MutationCreateTenantArgs = {
  tenantCreateInput: TenantCreateInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteCodeArgs = {
  code: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserByAccountIdArgs = {
  id: Scalars['String']['input'];
};


export type MutationEndCallArgs = {
  id: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLoginWithFirebaseArgs = {
  token: Scalars['String']['input'];
};


export type MutationMatchingArgs = {
  accountId: Scalars['String']['input'];
};


export type MutationRejectProposalArgs = {
  input: Scalars['String']['input'];
};


export type MutationRemoveAccountArgs = {
  accountId: Scalars['String']['input'];
};


export type MutationRemoveAllConnectionsFromAccountArgs = {
  accountId: Scalars['String']['input'];
};


export type MutationRemoveCallArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveConnectionArgs = {
  accountId: Scalars['String']['input'];
  connectionId: Scalars['String']['input'];
};


export type MutationResetMyPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationSchedulePushNotificationArgs = {
  FCM: Scalars['String']['input'];
  body: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationScheduleTenantIdPushNotificationArgs = {
  body: Scalars['String']['input'];
  tenantId?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  tokens?: InputMaybe<Array<Scalars['String']['input']>>;
  userType?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendPushNotificationArgs = {
  input: PushNotificationInput;
};


export type MutationSetFcmArgs = {
  token: Scalars['String']['input'];
};


export type MutationStartCallArgs = {
  id: Scalars['String']['input'];
};


export type MutationTransferConnectionArgs = {
  accountIdToBeTransferred: Scalars['String']['input'];
  newAccountId: Scalars['String']['input'];
  oldAccountId: Scalars['String']['input'];
};


export type MutationUpdateAccountArgs = {
  accountData: UpdateAccountInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateCallArgs = {
  id: Scalars['String']['input'];
  input: UpdateCallInput;
};


export type MutationUpdatePasswordArgs = {
  updatePasswordData: UpdatePasswordInput;
};


export type MutationUpdatePasswordWithCodeArgs = {
  code: Scalars['String']['input'];
  confirmation: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationUpdateTenantArgs = {
  tenantInput: TenantInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['String']['input'];
  input: UpdateUserInput;
};

export type News = {
  __typename?: 'News';
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  linkURL: Scalars['String']['output'];
  sourceText: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type NewsInput = {
  id: Scalars['String']['input'];
  imageUrl: Scalars['String']['input'];
  linkURL: Scalars['String']['input'];
  sourceText: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type NotificationTokensDto = {
  __typename?: 'NotificationTokensDTO';
  /** The APNS token for push notifications. */
  APNS?: Maybe<Scalars['String']['output']>;
  /** The FCM token for push notifications. */
  FCM?: Maybe<Scalars['String']['output']>;
  /** The Google token used to send calendar invitation for Gmail accounts. */
  googleServerAuthToken?: Maybe<Scalars['String']['output']>;
};

export type NotificationTokensInput = {
  /** The APNS token for push notifications */
  APNS?: InputMaybe<Scalars['String']['input']>;
  /** The FCM token for push notifications */
  FCM?: InputMaybe<Scalars['String']['input']>;
  /** The Google token used to send calendar invitation for Gmail accounts. */
  googleServerAuthToken?: InputMaybe<Scalars['String']['input']>;
};

export type OnboardingStatusDto = {
  __typename?: 'OnboardingStatusDTO';
  /** Indicates the availability status of the onboarding process. */
  availability: Scalars['Boolean']['output'];
  /** Indicates whether the onboarding process has found a match. */
  match: Scalars['Boolean']['output'];
  /** Indicates whether the personal information has been submitted in the onboarding process. */
  personalInfoSubmitted: Scalars['Boolean']['output'];
  /** Indicates whether the onboarding process has completed the quiz. */
  quizCompleted: Scalars['Boolean']['output'];
};

export type OnboardingStatusInput = {
  /** The availability status of the onboarding process */
  availability?: InputMaybe<Scalars['Boolean']['input']>;
  /** The match status of the onboarding process */
  match?: InputMaybe<Scalars['Boolean']['input']>;
  /** The submission status of personal information in the onboarding process */
  personalInfoSubmitted?: InputMaybe<Scalars['Boolean']['input']>;
  /** The completion status of the quiz in the onboarding process */
  quizCompleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PaginationMetadata = {
  __typename?: 'PaginationMetadata';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PrizePlacement = {
  __typename?: 'PrizePlacement';
  predefinedCallId?: Maybe<Scalars['Int']['output']>;
  prizeId?: Maybe<Scalars['Int']['output']>;
};

export type PrizePlacementInput = {
  predefinedCallId?: InputMaybe<Scalars['Int']['input']>;
  prizeId?: InputMaybe<Scalars['Int']['input']>;
};

export type Prizes = {
  __typename?: 'Prizes';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  img?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PrizesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  img?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PushNotificationInput = {
  /** FCM token */
  FCM: Scalars['String']['input'];
  /** Message content */
  content: Scalars['String']['input'];
  /** Title */
  title: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  /** Biulding analytics for mentors */
  buildAnalyticsReport: AnalyticsReportDto;
  call: CallDto;
  calls: Array<CallDto>;
  /** Retrieves a list of calls based on the current date and request options. */
  callsList: CallsListDto;
  /** Retrieve an account by its ID. */
  getAccount: AccountDto;
  /** Get Admin Profile Information */
  getAdminProfile: AdminDto;
  getCodesByMentorAccountId: Array<Code>;
  /** Get all mentors for a Tenant */
  getMentors: Array<AccountDto>;
  /** Retrieve current authenticated student Mentor account. */
  getMyMentorAccount: AccountDto;
  /** Get all students for a Tenant */
  getStudents: Array<AccountDto>;
  getTenant: TenantDto;
  /** Get user by ID */
  getUserById: UserDto;
  isValidCode: Code;
  /** Get the current authenticated user */
  me: MeDto;
  /** Get all mentors analytics */
  mentorsAnalytics: Array<MentorsAnalyticsDto>;
  metrics: Array<Metrics>;
  /** Get the current authenticated user calls */
  myCalls: CallsListDto;
  proposalsByAppliant: Array<CallProposalDto>;
  proposalsByCallId: Array<CallProposalDto>;
  proposalsByRequired: Array<CallProposalDto>;
  screenViewsCount: Scalars['Float']['output'];
  searchCodes: CodePaginatedResponse;
  /** Get all users */
  studentsAnalytics: Array<StudentsAnalyticsDto>;
  tenants: Array<TenantDto>;
  /** Get tenant analytics */
  tenantsAnalytics: TenantAnalyticsDto;
  totalUsageTime: Scalars['Float']['output'];
  /** Get all users */
  users: Array<UserDto>;
};


export type QueryCallArgs = {
  id: Scalars['String']['input'];
};


export type QueryCallsListArgs = {
  currentDate?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryGetAccountArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAdminProfileArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCodesByMentorAccountIdArgs = {
  mentorAccountId: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};


export type QueryGetMyMentorAccountArgs = {
  myAccountId: Scalars['String']['input'];
};


export type QueryGetTenantArgs = {
  tenantKey: Scalars['String']['input'];
};


export type QueryGetUserByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryIsValidCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryMetricsArgs = {
  filters?: InputMaybe<FilterMetricDto>;
};


export type QueryMyCallsArgs = {
  accountId: Scalars['String']['input'];
};


export type QueryProposalsByAppliantArgs = {
  appliantAccountId: Scalars['String']['input'];
};


export type QueryProposalsByCallIdArgs = {
  callId: Scalars['String']['input'];
};


export type QueryProposalsByRequiredArgs = {
  requiredAccountId: Scalars['String']['input'];
};


export type QueryScreenViewsCountArgs = {
  screenName: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type QuerySearchCodesArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  forcedWith?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<Scalars['Float']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  used?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryTotalUsageTimeArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  userType: Scalars['String']['input'];
};

export type QuestionDto = {
  __typename?: 'QuestionDTO';
  answer: Scalars['String']['output'];
  question: Scalars['String']['output'];
};

export type QuestionInputDto = {
  answer: Scalars['String']['input'];
  question: Scalars['String']['input'];
};

export type QuizDto = {
  __typename?: 'QuizDTO';
  funnyQuestion1: QuestionDto;
  funnyQuestion2: QuestionDto;
  funnyQuestion3: QuestionDto;
  personalQuestion1: QuestionDto;
  personalQuestion2: QuestionDto;
  personalQuestion3: QuestionDto;
  questionOrderFunny: Array<Scalars['Float']['output']>;
  questionOrderPersonality: Array<Scalars['Float']['output']>;
};

export type QuizInputDto = {
  funnyQuestion1: QuestionInputDto;
  funnyQuestion2: QuestionInputDto;
  funnyQuestion3: QuestionInputDto;
  personalQuestion1: QuestionInputDto;
  personalQuestion2: QuestionInputDto;
  personalQuestion3: QuestionInputDto;
  questionOrderFunny: Array<Scalars['Float']['input']>;
  questionOrderPersonality: Array<Scalars['Float']['input']>;
};

export type QuizOption = {
  __typename?: 'QuizOption';
  /** The image of the option. */
  image: Scalars['String']['output'];
  /** The title of the option. */
  title: Scalars['String']['output'];
};

export type QuizOptionInput = {
  /** The image of the option. */
  image: Scalars['String']['input'];
  /** The title of the option. */
  title: Scalars['String']['input'];
};

export type QuizQuestion = {
  __typename?: 'QuizQuestion';
  /** The category of the question. */
  category: Scalars['String']['output'];
  /** The options of the question. */
  options: Array<QuizOption>;
  /** The question of the answer. */
  question: Scalars['String']['output'];
  /** The type of the question. */
  type: Scalars['String']['output'];
  /** The weight of the question. */
  weight: Scalars['Float']['output'];
};

export type QuizQuestionInput = {
  /** The category of the question. */
  category: Scalars['String']['input'];
  /** The options of the question. */
  options: Array<QuizOptionInput>;
  /** The question of the answer. */
  question: Scalars['String']['input'];
  /** The type of the question. */
  type: Scalars['String']['input'];
  /** The weight of the question. */
  weight: Scalars['Float']['input'];
};

export type QuizResponse = {
  __typename?: 'QuizResponse';
  /** The answers of the quiz. */
  answers?: Maybe<Array<Answer>>;
  /** The total number of questions. */
  totalQuestions: Scalars['Float']['output'];
};

export type QuizResponseInput = {
  /** The answers of the quiz. */
  answers?: InputMaybe<Array<AnswerInput>>;
  /** The total number of questions. */
  totalQuestions: Scalars['Float']['input'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  /** The user object without the password field */
  success: Scalars['Boolean']['output'];
};

/** The role of a User */
export enum Role {
  Admin = 'ADMIN',
  Mentor = 'MENTOR',
  Student = 'STUDENT',
  Superadmin = 'SUPERADMIN'
}

export type Sdui = {
  __typename?: 'SDUI';
  university: University;
};

export type SduiInput = {
  university: CreateUniversityInput;
};

export type SendBirdUserDto = {
  __typename?: 'SendBirdUserDTO';
  /** Access token for the SB account. */
  accessToken?: Maybe<Scalars['String']['output']>;
  /** The ID of the SB account. */
  userId: Scalars['String']['output'];
};

export type SlotDto = {
  __typename?: 'SlotDTO';
  from: Scalars['String']['output'];
  status?: Maybe<Status>;
  to: Scalars['String']['output'];
};

export type SlotInputDto = {
  from: Scalars['String']['input'];
  status?: InputMaybe<Status>;
  to: Scalars['String']['input'];
};

/** The status of a slot */
export enum Status {
  Available = 'available',
  Scheduled = 'scheduled',
  Unavailable = 'unavailable'
}

export type StudentsAnalyticsDto = {
  __typename?: 'StudentsAnalyticsDTO';
  /** The ID of the student. */
  accountId?: Maybe<Scalars['ObjectId']['output']>;
  /** The name of the student. */
  displayName?: Maybe<Scalars['String']['output']>;
  /** Last activity date of the mentor. */
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  /** Last data sync date of the mentor. */
  lastCallAt?: Maybe<Scalars['DateTime']['output']>;
  /** Last data sync date of the mentor. */
  lastSyncAt?: Maybe<Scalars['DateTime']['output']>;
  /** The mentor of the student. */
  mentor?: Maybe<Scalars['String']['output']>;
  /** The mentor ID of the student. */
  mentorId?: Maybe<Scalars['ObjectId']['output']>;
  /** The progress of the student. */
  progress: Scalars['Float']['output'];
  /** The status of the student. */
  status: Scalars['String']['output'];
  /** The number of calls the student has made. */
  totalDoneCalls: Scalars['Int']['output'];
  /** The number of calls the student has made. */
  totalUpcomingCalls: Scalars['Int']['output'];
  /** The type of the student. */
  type: Scalars['String']['output'];
  /** The ID of the student. */
  userId?: Maybe<Scalars['ObjectId']['output']>;
};

export type TenantAnalyticsDto = {
  __typename?: 'TenantAnalyticsDTO';
  averageCallsDuration: Scalars['Float']['output'];
  /** Last activity date of the mentor. */
  lastSyncAt: Scalars['DateTime']['output'];
  /** The number of messages the mentor has sent. */
  nMessages: Scalars['Int']['output'];
  totalActiveUsersLast14Days?: Maybe<Scalars['Int']['output']>;
  totalCallsDuration?: Maybe<Scalars['Int']['output']>;
  totalDoneCalls?: Maybe<Scalars['Int']['output']>;
  totalStudentsOnboarded?: Maybe<Scalars['Int']['output']>;
  totalUpcomingCalls?: Maybe<Scalars['Int']['output']>;
  /** The type of the analytics. */
  type?: Maybe<Scalars['String']['output']>;
};

export type TenantConfigDto = {
  __typename?: 'TenantConfigDTO';
  /** Google Messurement ID */
  GAC?: Maybe<Scalars['String']['output']>;
  /** Application version */
  appVersion?: Maybe<Scalars['String']['output']>;
  /** List of call titles and topics */
  callsTitlesTopics?: Maybe<Array<CallTitleTopic>>;
  /** Google Maps API key */
  googleMapsApiKey?: Maybe<Scalars['String']['output']>;
  /** Google Web Client ID */
  googleWebClientId?: Maybe<Scalars['String']['output']>;
  /** List of majors */
  majorsList?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum number of students per mentor */
  maxStudentsPerMentor: Scalars['Int']['output'];
  /** List of prize placement */
  prizePlacement?: Maybe<Array<PrizePlacement>>;
  /** List of prizes */
  prizes?: Maybe<Array<Prizes>>;
  /** Default quiz questions of the tenant. */
  quiz?: Maybe<Array<QuizQuestion>>;
  /** Sendbird API Token */
  sendbirdApiToken?: Maybe<Scalars['String']['output']>;
  /** Sendbird App ID */
  sendbirdAppId?: Maybe<Scalars['String']['output']>;
  /** List of test emails */
  testEmails?: Maybe<Array<Scalars['String']['output']>>;
};

export type TenantConfigInputDto = {
  /** Google Messurement ID */
  GAC?: InputMaybe<Scalars['String']['input']>;
  /** Application version */
  appVersion?: InputMaybe<Scalars['String']['input']>;
  /** List of call titles and topics */
  callsTitlesTopics?: InputMaybe<Array<CallTitleTopicInput>>;
  /** Google Maps API key */
  googleMapsApiKey?: InputMaybe<Scalars['String']['input']>;
  /** Google Web Client ID */
  googleWebClientId?: InputMaybe<Scalars['String']['input']>;
  /** List of majors */
  majorsList?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Maximum number of students per mentor */
  maxStudentsPerMentor: Scalars['Int']['input'];
  /** List of prize placement */
  prizePlacement?: InputMaybe<Array<PrizePlacementInput>>;
  /** List of prizes */
  prizes?: InputMaybe<Array<PrizesInput>>;
  /** Default quiz questions of the tenant. */
  quiz?: InputMaybe<Array<QuizQuestionInput>>;
  /** Sendbird API Token */
  sendbirdApiToken?: InputMaybe<Scalars['String']['input']>;
  /** Sendbird App ID */
  sendbirdAppId?: InputMaybe<Scalars['String']['input']>;
  /** List of test emails */
  testEmails?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TenantCreateInput = {
  /** The domain name of the tenant. */
  domain: Scalars['String']['input'];
  /** The ID KEY of the tenant. */
  id: Scalars['String']['input'];
  /** The name of the tenant. */
  name: Scalars['String']['input'];
  /** The storage bucket for the tenant. */
  storageBucket: Scalars['String']['input'];
};

export type TenantDto = {
  __typename?: 'TenantDTO';
  /** Server-driven UI configuration for the tenant */
  SDUI?: Maybe<Sdui>;
  /** Server-driven UI configuration for the tenant */
  configs?: Maybe<TenantConfigDto>;
  /** Tenant Doamin name */
  domain?: Maybe<Scalars['String']['output']>;
  /** Unique identifier for the tenant */
  id: Scalars['ID']['output'];
  /** Tenant ID key that is used to select the correct tenant */
  key: Scalars['String']['output'];
  /** Tenant name */
  name: Scalars['String']['output'];
  /** Storage bucket used by the tenant */
  storageBucket?: Maybe<Scalars['String']['output']>;
};

export type TenantInput = {
  /** The server-driven UI configuration of the tenant */
  SDUI?: InputMaybe<SduiInput>;
  configs?: TenantConfigInputDto;
  /** The domain of the tenant */
  domain?: InputMaybe<Scalars['String']['input']>;
  /** Unique identifier for the tenant */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Tenant ID key that is used to select the correct tenant */
  key: Scalars['String']['input'];
  /** The name of the tenant */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The storage bucket of the tenant */
  storageBucket?: InputMaybe<Scalars['String']['input']>;
};

export type Topic = {
  __typename?: 'Topic';
  id?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type TopicInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type University = {
  __typename?: 'University';
  brandingDescription: Scalars['String']['output'];
  coatOfArms: Scalars['String']['output'];
  commonApplication: CommonApplication;
  description: Scalars['String']['output'];
  events: Array<Event>;
  latestNews: Array<News>;
  logo: Scalars['String']['output'];
  name: Scalars['String']['output'];
  profileImage: Scalars['String']['output'];
  videos: Array<VideoDto>;
};

export type UpdateAccountInput = {
  /** The connections associated with the account. */
  accountConnections?: InputMaybe<Array<ConnectionInputDto>>;
  /** The calls associated with the account. */
  availability?: InputMaybe<Array<AvailabilityInputDto>>;
  /** The calls associated with the account. */
  calls?: InputMaybe<Array<CallInput>>;
  /** The code of the account. */
  code?: InputMaybe<CreateCodeInput>;
  /** The creation date of the account. */
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The ID of the account. */
  id?: InputMaybe<Scalars['ObjectId']['input']>;
  /** Student journey */
  journey?: InputMaybe<AccountJourneyInputDto>;
  /** The ID of the student Mentor. */
  mentorId?: InputMaybe<Scalars['ObjectId']['input']>;
  /** The notification tokens for the account. */
  notificationTokens?: InputMaybe<NotificationTokensInput>;
  /** The onboarding status for the account. */
  onboardingStatus?: InputMaybe<OnboardingStatusInput>;
  /** The profile information for the account. */
  profile?: InputMaybe<AccountProfileInput>;
  /** The quiz response of the account. */
  quizResponse?: InputMaybe<QuizResponseInput>;
  /** Mentor rating. */
  rating?: InputMaybe<Scalars['Int']['input']>;
  /** The SB account. */
  sendBirdUser?: InputMaybe<InputSendBirdUserDto>;
  /** The ID of the tenant associated with the account. */
  tenantId?: InputMaybe<Scalars['String']['input']>;
  /** The last update date of the account. */
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The user information for the account. */
  user?: InputMaybe<CreateUserInput>;
};

export type UpdateCallInput = {
  /** The ID of the call */
  accountId?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if the call lasts all day */
  allDay?: InputMaybe<Scalars['Boolean']['input']>;
  /** Details of the partner involved in the call */
  attendees?: InputMaybe<Array<Scalars['ObjectId']['input']>>;
  /** Mentor Collage associated with the call */
  college?: InputMaybe<Scalars['String']['input']>;
  /** The date and time of the call. */
  dateTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Default time zone of the call */
  defaultTz?: InputMaybe<Scalars['String']['input']>;
  /** Call details */
  details?: InputMaybe<CallDetailsInputDto>;
  /** Indicates if the call is done. */
  done?: InputMaybe<Scalars['Boolean']['input']>;
  /** Duration of the call in minutes */
  duration?: InputMaybe<Scalars['Float']['input']>;
  endedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** High school associated with the call */
  highSchool?: InputMaybe<Scalars['String']['input']>;
  /** Note */
  note?: InputMaybe<Scalars['String']['input']>;
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Call Status */
  status?: InputMaybe<Scalars['String']['input']>;
  /** The topics discussed in the call. */
  topics?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdatePasswordInput = {
  /** The new password */
  newPassword: Scalars['String']['input'];
  /** Confirmation of the new password */
  newPasswordConfirmation: Scalars['String']['input'];
};

export type UpdateUserInput = {
  /** Authenticated User access token */
  accessToken?: InputMaybe<Scalars['String']['input']>;
  /** The accounts associated with the user. */
  accountsLinked?: Array<AccountLinkInput>;
  /** User signup code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** The email of the user. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if the email of the user is verified. */
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  /** The Firebase UIDs associated with the user. */
  firebaseUid?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The name of the user. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The password of the user. */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Indicates if the user's password has been changed. */
  passwordChanged?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates if the user's password has been reset. */
  passwordReset?: InputMaybe<Scalars['Boolean']['input']>;
  /** User type string */
  type?: InputMaybe<Role>;
  /** The username of the user. */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UserDto = {
  __typename?: 'UserDTO';
  /** Authenticated User access token */
  accessToken?: Maybe<Scalars['String']['output']>;
  /** List of associated accounts */
  accountsLinked: Array<AccountLinkDto>;
  /** Timestamp of when the user was created */
  createdAt: Scalars['String']['output'];
  /** User email address */
  email: Scalars['String']['output'];
  /** Indicates whether the email is verified */
  emailVerified: Scalars['Boolean']['output'];
  /** Timestamp of when the email was verified */
  emailVerifiedAt: Scalars['String']['output'];
  /** List of Firebase UIDs associated with the user */
  firebaseUid?: Maybe<Array<Scalars['String']['output']>>;
  /** User ID */
  id: Scalars['ObjectId']['output'];
  /** User full name */
  name?: Maybe<Scalars['String']['output']>;
  /** User password (hashed) */
  password: Scalars['String']['output'];
  /** Indicates whether the password has been changed */
  passwordChanged: Scalars['Boolean']['output'];
  /** Timestamp of when the password was last changed */
  passwordChangedAt: Scalars['String']['output'];
  /** Indicates whether the password has been reset */
  passwordReset: Scalars['Boolean']['output'];
  /** Timestamp of when the password was last reset */
  passwordResetAt: Scalars['String']['output'];
  /** User type */
  type: Scalars['String']['output'];
  /** Timestamp of the last update to the user */
  updatedAt: Scalars['String']['output'];
  /** Username chosen by the user */
  username?: Maybe<Scalars['String']['output']>;
};

export type VideoDto = {
  __typename?: 'VideoDTO';
  id?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type VideoInputDto = {
  id?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AccountConnetionsPopulated: ResolverTypeWrapper<AccountConnetionsPopulated>;
  AccountDTO: ResolverTypeWrapper<AccountDto>;
  AccountInput: AccountInput;
  AccountJourneyDTO: ResolverTypeWrapper<AccountJourneyDto>;
  AccountJourneyInputDTO: AccountJourneyInputDto;
  AccountLinkDTO: ResolverTypeWrapper<AccountLinkDto>;
  AccountLinkInput: AccountLinkInput;
  AccountLocationDTO: ResolverTypeWrapper<AccountLocationDto>;
  AccountLocationInput: AccountLocationInput;
  AccountProfileDTO: ResolverTypeWrapper<AccountProfileDto>;
  AccountProfileInput: AccountProfileInput;
  AdminAuthPayload: ResolverTypeWrapper<AdminAuthPayload>;
  AdminDTO: ResolverTypeWrapper<AdminDto>;
  AdminInputDTO: AdminInputDto;
  AnalyticsReportDTO: ResolverTypeWrapper<AnalyticsReportDto>;
  Answer: ResolverTypeWrapper<Answer>;
  AnswerInput: AnswerInput;
  AuthInput: AuthInput;
  AuthPayload: ResolverTypeWrapper<AuthPayload>;
  AvailabilityDTO: ResolverTypeWrapper<AvailabilityDto>;
  AvailabilityInputDTO: AvailabilityInputDto;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  BulkCreateCodeInput: BulkCreateCodeInput;
  CallDTO: ResolverTypeWrapper<CallDto>;
  CallDetailsDto: ResolverTypeWrapper<CallDetailsDto>;
  CallDetailsInputDto: CallDetailsInputDto;
  CallInput: CallInput;
  CallProposalDTO: ResolverTypeWrapper<CallProposalDto>;
  CallProposalInputDTO: CallProposalInputDto;
  CallTitleTopic: ResolverTypeWrapper<CallTitleTopic>;
  CallTitleTopicInput: CallTitleTopicInput;
  CallsListDTO: ResolverTypeWrapper<CallsListDto>;
  Code: ResolverTypeWrapper<Code>;
  CodePaginatedResponse: ResolverTypeWrapper<CodePaginatedResponse>;
  CommonApplication: ResolverTypeWrapper<CommonApplication>;
  CommonApplicationInput: CommonApplicationInput;
  ConnectionDTO: ResolverTypeWrapper<ConnectionDto>;
  ConnectionInputDTO: ConnectionInputDto;
  ConnectionTypes: ConnectionTypes;
  CreateCallInput: CreateCallInput;
  CreateCodeInput: CreateCodeInput;
  CreateMetricDto: CreateMetricDto;
  CreateUniversityInput: CreateUniversityInput;
  CreateUserInput: CreateUserInput;
  CurrentAccountDTO: ResolverTypeWrapper<CurrentAccountDto>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  Event: ResolverTypeWrapper<Event>;
  EventInput: EventInput;
  FilterMetricDto: FilterMetricDto;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  Front: ResolverTypeWrapper<Front>;
  FrontInput: FrontInput;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  InputSendBirdUserDTO: InputSendBirdUserDto;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  LoginInput: LoginInput;
  MeDTO: ResolverTypeWrapper<MeDto>;
  MentorsAnalyticsDTO: ResolverTypeWrapper<MentorsAnalyticsDto>;
  Metrics: ResolverTypeWrapper<Metrics>;
  Mutation: ResolverTypeWrapper<{}>;
  News: ResolverTypeWrapper<News>;
  NewsInput: NewsInput;
  NotificationTokensDTO: ResolverTypeWrapper<NotificationTokensDto>;
  NotificationTokensInput: NotificationTokensInput;
  ObjectId: ResolverTypeWrapper<Scalars['ObjectId']['output']>;
  OnboardingStatusDTO: ResolverTypeWrapper<OnboardingStatusDto>;
  OnboardingStatusInput: OnboardingStatusInput;
  PaginationMetadata: ResolverTypeWrapper<PaginationMetadata>;
  PrizePlacement: ResolverTypeWrapper<PrizePlacement>;
  PrizePlacementInput: PrizePlacementInput;
  Prizes: ResolverTypeWrapper<Prizes>;
  PrizesInput: PrizesInput;
  PushNotificationInput: PushNotificationInput;
  Query: ResolverTypeWrapper<{}>;
  QuestionDTO: ResolverTypeWrapper<QuestionDto>;
  QuestionInputDTO: QuestionInputDto;
  QuizDTO: ResolverTypeWrapper<QuizDto>;
  QuizInputDTO: QuizInputDto;
  QuizOption: ResolverTypeWrapper<QuizOption>;
  QuizOptionInput: QuizOptionInput;
  QuizQuestion: ResolverTypeWrapper<QuizQuestion>;
  QuizQuestionInput: QuizQuestionInput;
  QuizResponse: ResolverTypeWrapper<QuizResponse>;
  QuizResponseInput: QuizResponseInput;
  ResetPasswordResponse: ResolverTypeWrapper<ResetPasswordResponse>;
  Role: Role;
  SDUI: ResolverTypeWrapper<Sdui>;
  SDUIInput: SduiInput;
  SendBirdUserDTO: ResolverTypeWrapper<SendBirdUserDto>;
  SlotDTO: ResolverTypeWrapper<SlotDto>;
  SlotInputDTO: SlotInputDto;
  Status: Status;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  StudentsAnalyticsDTO: ResolverTypeWrapper<StudentsAnalyticsDto>;
  TenantAnalyticsDTO: ResolverTypeWrapper<TenantAnalyticsDto>;
  TenantConfigDTO: ResolverTypeWrapper<TenantConfigDto>;
  TenantConfigInputDTO: TenantConfigInputDto;
  TenantCreateInput: TenantCreateInput;
  TenantDTO: ResolverTypeWrapper<TenantDto>;
  TenantInput: TenantInput;
  Topic: ResolverTypeWrapper<Topic>;
  TopicInput: TopicInput;
  University: ResolverTypeWrapper<University>;
  UpdateAccountInput: UpdateAccountInput;
  UpdateCallInput: UpdateCallInput;
  UpdatePasswordInput: UpdatePasswordInput;
  UpdateUserInput: UpdateUserInput;
  UserDTO: ResolverTypeWrapper<UserDto>;
  VideoDTO: ResolverTypeWrapper<VideoDto>;
  VideoInputDTO: VideoInputDto;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AccountConnetionsPopulated: AccountConnetionsPopulated;
  AccountDTO: AccountDto;
  AccountInput: AccountInput;
  AccountJourneyDTO: AccountJourneyDto;
  AccountJourneyInputDTO: AccountJourneyInputDto;
  AccountLinkDTO: AccountLinkDto;
  AccountLinkInput: AccountLinkInput;
  AccountLocationDTO: AccountLocationDto;
  AccountLocationInput: AccountLocationInput;
  AccountProfileDTO: AccountProfileDto;
  AccountProfileInput: AccountProfileInput;
  AdminAuthPayload: AdminAuthPayload;
  AdminDTO: AdminDto;
  AdminInputDTO: AdminInputDto;
  AnalyticsReportDTO: AnalyticsReportDto;
  Answer: Answer;
  AnswerInput: AnswerInput;
  AuthInput: AuthInput;
  AuthPayload: AuthPayload;
  AvailabilityDTO: AvailabilityDto;
  AvailabilityInputDTO: AvailabilityInputDto;
  Boolean: Scalars['Boolean']['output'];
  BulkCreateCodeInput: BulkCreateCodeInput;
  CallDTO: CallDto;
  CallDetailsDto: CallDetailsDto;
  CallDetailsInputDto: CallDetailsInputDto;
  CallInput: CallInput;
  CallProposalDTO: CallProposalDto;
  CallProposalInputDTO: CallProposalInputDto;
  CallTitleTopic: CallTitleTopic;
  CallTitleTopicInput: CallTitleTopicInput;
  CallsListDTO: CallsListDto;
  Code: Code;
  CodePaginatedResponse: CodePaginatedResponse;
  CommonApplication: CommonApplication;
  CommonApplicationInput: CommonApplicationInput;
  ConnectionDTO: ConnectionDto;
  ConnectionInputDTO: ConnectionInputDto;
  CreateCallInput: CreateCallInput;
  CreateCodeInput: CreateCodeInput;
  CreateMetricDto: CreateMetricDto;
  CreateUniversityInput: CreateUniversityInput;
  CreateUserInput: CreateUserInput;
  CurrentAccountDTO: CurrentAccountDto;
  DateTime: Scalars['DateTime']['output'];
  Event: Event;
  EventInput: EventInput;
  FilterMetricDto: FilterMetricDto;
  Float: Scalars['Float']['output'];
  Front: Front;
  FrontInput: FrontInput;
  ID: Scalars['ID']['output'];
  InputSendBirdUserDTO: InputSendBirdUserDto;
  Int: Scalars['Int']['output'];
  JSON: Scalars['JSON']['output'];
  LoginInput: LoginInput;
  MeDTO: MeDto;
  MentorsAnalyticsDTO: MentorsAnalyticsDto;
  Metrics: Metrics;
  Mutation: {};
  News: News;
  NewsInput: NewsInput;
  NotificationTokensDTO: NotificationTokensDto;
  NotificationTokensInput: NotificationTokensInput;
  ObjectId: Scalars['ObjectId']['output'];
  OnboardingStatusDTO: OnboardingStatusDto;
  OnboardingStatusInput: OnboardingStatusInput;
  PaginationMetadata: PaginationMetadata;
  PrizePlacement: PrizePlacement;
  PrizePlacementInput: PrizePlacementInput;
  Prizes: Prizes;
  PrizesInput: PrizesInput;
  PushNotificationInput: PushNotificationInput;
  Query: {};
  QuestionDTO: QuestionDto;
  QuestionInputDTO: QuestionInputDto;
  QuizDTO: QuizDto;
  QuizInputDTO: QuizInputDto;
  QuizOption: QuizOption;
  QuizOptionInput: QuizOptionInput;
  QuizQuestion: QuizQuestion;
  QuizQuestionInput: QuizQuestionInput;
  QuizResponse: QuizResponse;
  QuizResponseInput: QuizResponseInput;
  ResetPasswordResponse: ResetPasswordResponse;
  SDUI: Sdui;
  SDUIInput: SduiInput;
  SendBirdUserDTO: SendBirdUserDto;
  SlotDTO: SlotDto;
  SlotInputDTO: SlotInputDto;
  String: Scalars['String']['output'];
  StudentsAnalyticsDTO: StudentsAnalyticsDto;
  TenantAnalyticsDTO: TenantAnalyticsDto;
  TenantConfigDTO: TenantConfigDto;
  TenantConfigInputDTO: TenantConfigInputDto;
  TenantCreateInput: TenantCreateInput;
  TenantDTO: TenantDto;
  TenantInput: TenantInput;
  Topic: Topic;
  TopicInput: TopicInput;
  University: University;
  UpdateAccountInput: UpdateAccountInput;
  UpdateCallInput: UpdateCallInput;
  UpdatePasswordInput: UpdatePasswordInput;
  UpdateUserInput: UpdateUserInput;
  UserDTO: UserDto;
  VideoDTO: VideoDto;
  VideoInputDTO: VideoInputDto;
};

export type AccountConnetionsPopulatedResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountConnetionsPopulated'] = ResolversParentTypes['AccountConnetionsPopulated']> = {
  connectedWith?: Resolver<Maybe<ResolversTypes['AccountDTO']>, ParentType, ContextType>;
  connectionType?: Resolver<Maybe<ResolversTypes['ConnectionTypes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountDTO'] = ResolversParentTypes['AccountDTO']> = {
  _id?: Resolver<Maybe<ResolversTypes['ObjectId']>, ParentType, ContextType>;
  accountConnections?: Resolver<Maybe<Array<ResolversTypes['ConnectionDTO']>>, ParentType, ContextType>;
  availability?: Resolver<Maybe<Array<ResolversTypes['AvailabilityDTO']>>, ParentType, ContextType>;
  calls?: Resolver<Maybe<Array<ResolversTypes['CallDTO']>>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['Code']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  disabledAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ObjectId']>, ParentType, ContextType>;
  journey?: Resolver<Maybe<ResolversTypes['AccountJourneyDTO']>, ParentType, ContextType>;
  mentorId?: Resolver<Maybe<ResolversTypes['ObjectId']>, ParentType, ContextType>;
  notificationTokens?: Resolver<Maybe<ResolversTypes['NotificationTokensDTO']>, ParentType, ContextType>;
  onboardingStatus?: Resolver<Maybe<ResolversTypes['OnboardingStatusDTO']>, ParentType, ContextType>;
  profile?: Resolver<ResolversTypes['AccountProfileDTO'], ParentType, ContextType>;
  quizResponse?: Resolver<Maybe<ResolversTypes['QuizResponse']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sendBirdUser?: Resolver<Maybe<ResolversTypes['SendBirdUserDTO']>, ParentType, ContextType>;
  tenantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  userType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountJourneyDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountJourneyDTO'] = ResolversParentTypes['AccountJourneyDTO']> = {
  callsTitlesTopics?: Resolver<Maybe<Array<ResolversTypes['CallTitleTopic']>>, ParentType, ContextType>;
  prizesPlacement?: Resolver<Maybe<Array<ResolversTypes['PrizePlacement']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountLinkDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountLinkDTO'] = ResolversParentTypes['AccountLinkDTO']> = {
  account?: Resolver<Maybe<ResolversTypes['AccountDTO']>, ParentType, ContextType>;
  accountId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  tenantKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountLocationDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountLocationDTO'] = ResolversParentTypes['AccountLocationDTO']> = {
  latitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  longitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountProfileDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountProfileDTO'] = ResolversParentTypes['AccountProfileDTO']> = {
  college?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ethnicity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gradeLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  highSchool?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  intendedMajor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  interests?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['AccountLocationDTO']>, ParentType, ContextType>;
  major?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  majorYear?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  onboardingQuiz?: Resolver<ResolversTypes['QuizDTO'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  photoURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videos?: Resolver<Maybe<Array<ResolversTypes['VideoDTO']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminAuthPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminAuthPayload'] = ResolversParentTypes['AdminAuthPayload']> = {
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  admin?: Resolver<ResolversTypes['AdminDTO'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminDTO'] = ResolversParentTypes['AdminDTO']> = {
  _id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  accessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  adminLevel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  password?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profilePhotoUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsReportDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnalyticsReportDTO'] = ResolversParentTypes['AnalyticsReportDTO']> = {
  documents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sucess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Answer'] = ResolversParentTypes['Answer']> = {
  answer?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  questionType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  weight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuthPayload'] = ResolversParentTypes['AuthPayload']> = {
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserDTO'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvailabilityDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['AvailabilityDTO'] = ResolversParentTypes['AvailabilityDTO']> = {
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slots?: Resolver<Array<ResolversTypes['SlotDTO']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CallDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['CallDTO'] = ResolversParentTypes['CallDTO']> = {
  accountId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  allDay?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  attendees?: Resolver<Array<ResolversTypes['AccountDTO']>, ParentType, ContextType>;
  college?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  defaultTz?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['CallDetailsDto']>, ParentType, ContextType>;
  done?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  endedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  highSchool?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topics?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CallDetailsDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['CallDetailsDto'] = ResolversParentTypes['CallDetailsDto']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topics?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CallProposalDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['CallProposalDTO'] = ResolversParentTypes['CallProposalDTO']> = {
  accepted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  applicantId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  callId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  dateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ObjectId']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requiredId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CallTitleTopicResolvers<ContextType = any, ParentType extends ResolversParentTypes['CallTitleTopic'] = ResolversParentTypes['CallTitleTopic']> = {
  call?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  front?: Resolver<Maybe<ResolversTypes['Front']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  videos?: Resolver<Maybe<Array<ResolversTypes['VideoDTO']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CallsListDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['CallsListDTO'] = ResolversParentTypes['CallsListDTO']> = {
  all?: Resolver<Maybe<Array<ResolversTypes['CallDTO']>>, ParentType, ContextType>;
  canceled?: Resolver<Maybe<Array<ResolversTypes['CallDTO']>>, ParentType, ContextType>;
  done?: Resolver<Maybe<Array<ResolversTypes['CallDTO']>>, ParentType, ContextType>;
  month?: Resolver<Maybe<Array<ResolversTypes['CallDTO']>>, ParentType, ContextType>;
  noShow?: Resolver<Maybe<Array<ResolversTypes['CallDTO']>>, ParentType, ContextType>;
  past?: Resolver<Maybe<Array<ResolversTypes['CallDTO']>>, ParentType, ContextType>;
  rejected?: Resolver<Maybe<Array<ResolversTypes['CallDTO']>>, ParentType, ContextType>;
  today?: Resolver<Maybe<Array<ResolversTypes['CallDTO']>>, ParentType, ContextType>;
  tomorrow?: Resolver<Maybe<Array<ResolversTypes['CallDTO']>>, ParentType, ContextType>;
  upcoming?: Resolver<Maybe<Array<ResolversTypes['CallDTO']>>, ParentType, ContextType>;
  week?: Resolver<Maybe<Array<ResolversTypes['CallDTO']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Code'] = ResolversParentTypes['Code']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  forceConnectionWith?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  tenantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  used?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CodePaginatedResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CodePaginatedResponse'] = ResolversParentTypes['CodePaginatedResponse']> = {
  data?: Resolver<Array<ResolversTypes['Code']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['PaginationMetadata'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommonApplicationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommonApplication'] = ResolversParentTypes['CommonApplication']> = {
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConnectionDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConnectionDTO'] = ResolversParentTypes['ConnectionDTO']> = {
  connectedWith?: Resolver<Maybe<ResolversTypes['AccountDTO']>, ParentType, ContextType>;
  connectionType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrentAccountDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrentAccountDTO'] = ResolversParentTypes['CurrentAccountDTO']> = {
  accountConnections?: Resolver<Maybe<Array<ResolversTypes['AccountConnetionsPopulated']>>, ParentType, ContextType>;
  availability?: Resolver<Maybe<Array<ResolversTypes['AvailabilityDTO']>>, ParentType, ContextType>;
  calls?: Resolver<Maybe<Array<ResolversTypes['CallDTO']>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  disabledAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ObjectId']>, ParentType, ContextType>;
  journey?: Resolver<Maybe<ResolversTypes['AccountJourneyDTO']>, ParentType, ContextType>;
  mentorId?: Resolver<Maybe<ResolversTypes['ObjectId']>, ParentType, ContextType>;
  notificationTokens?: Resolver<Maybe<ResolversTypes['NotificationTokensDTO']>, ParentType, ContextType>;
  onboardingStatus?: Resolver<Maybe<ResolversTypes['OnboardingStatusDTO']>, ParentType, ContextType>;
  profile?: Resolver<Maybe<ResolversTypes['AccountProfileDTO']>, ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sendBirdUser?: Resolver<Maybe<ResolversTypes['SendBirdUserDTO']>, ParentType, ContextType>;
  tenantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['ObjectId']>, ParentType, ContextType>;
  userType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type EventResolvers<ContextType = any, ParentType extends ResolversParentTypes['Event'] = ResolversParentTypes['Event']> = {
  dateTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  linkURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FrontResolvers<ContextType = any, ParentType extends ResolversParentTypes['Front'] = ResolversParentTypes['Front']> = {
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topics?: Resolver<Maybe<Array<ResolversTypes['Topic']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type MeDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['MeDTO'] = ResolversParentTypes['MeDTO']> = {
  accessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  accountsLinked?: Resolver<Array<ResolversTypes['AccountLinkDTO']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currentAccount?: Resolver<Maybe<ResolversTypes['CurrentAccountDTO']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  emailVerifiedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firebaseUid?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  password?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  passwordChanged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  passwordChangedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  passwordReset?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  passwordResetAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorsAnalyticsDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorsAnalyticsDTO'] = ResolversParentTypes['MentorsAnalyticsDTO']> = {
  accountId?: Resolver<Maybe<ResolversTypes['ObjectId']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastActivityAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastCallAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastSyncAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  major?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  meanProgressions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  photoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalDoneCalls?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalMentees?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalUpcomingCalls?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['ObjectId']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetricsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Metrics'] = ResolversParentTypes['Metrics']> = {
  metadata?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  metrics?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  acceptProposal?: Resolver<ResolversTypes['CallProposalDTO'], ParentType, ContextType, RequireFields<MutationAcceptProposalArgs, 'input'>>;
  addConnection?: Resolver<ResolversTypes['AccountDTO'], ParentType, ContextType, RequireFields<MutationAddConnectionArgs, 'accountId' | 'connectionId'>>;
  adminSigningIn?: Resolver<ResolversTypes['AdminAuthPayload'], ParentType, ContextType, RequireFields<MutationAdminSigningInArgs, 'input'>>;
  bulkCreateCode?: Resolver<Array<ResolversTypes['Code']>, ParentType, ContextType, RequireFields<MutationBulkCreateCodeArgs, 'bulkCreateCodeInput'>>;
  cancelCall?: Resolver<ResolversTypes['CallDTO'], ParentType, ContextType, RequireFields<MutationCancelCallArgs, 'id'>>;
  createAccount?: Resolver<ResolversTypes['AccountDTO'], ParentType, ContextType, RequireFields<MutationCreateAccountArgs, 'accountData'>>;
  createAccountWithToken?: Resolver<ResolversTypes['AuthPayload'], ParentType, ContextType, RequireFields<MutationCreateAccountWithTokenArgs, 'authData' | 'code' | 'tenantKey'>>;
  createAdmin?: Resolver<ResolversTypes['AdminDTO'], ParentType, ContextType, RequireFields<MutationCreateAdminArgs, 'input'>>;
  createCall?: Resolver<ResolversTypes['CallDTO'], ParentType, ContextType, RequireFields<MutationCreateCallArgs, 'input'>>;
  createCallProposal?: Resolver<ResolversTypes['CallProposalDTO'], ParentType, ContextType, RequireFields<MutationCreateCallProposalArgs, 'input'>>;
  createCode?: Resolver<ResolversTypes['Code'], ParentType, ContextType, RequireFields<MutationCreateCodeArgs, 'createCodeInput'>>;
  createMetric?: Resolver<ResolversTypes['Metrics'], ParentType, ContextType, RequireFields<MutationCreateMetricArgs, 'createMetricDto'>>;
  createTenant?: Resolver<ResolversTypes['TenantDTO'], ParentType, ContextType, RequireFields<MutationCreateTenantArgs, 'tenantCreateInput'>>;
  createUser?: Resolver<ResolversTypes['UserDTO'], ParentType, ContextType, RequireFields<MutationCreateUserArgs, 'input'>>;
  deleteCode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteCodeArgs, 'code'>>;
  deleteUser?: Resolver<ResolversTypes['UserDTO'], ParentType, ContextType, RequireFields<MutationDeleteUserArgs, 'id'>>;
  deleteUserByAccountId?: Resolver<ResolversTypes['UserDTO'], ParentType, ContextType, RequireFields<MutationDeleteUserByAccountIdArgs, 'id'>>;
  endCall?: Resolver<ResolversTypes['CallDTO'], ParentType, ContextType, RequireFields<MutationEndCallArgs, 'id'>>;
  login?: Resolver<ResolversTypes['AuthPayload'], ParentType, ContextType, RequireFields<MutationLoginArgs, 'input'>>;
  loginWithFirebase?: Resolver<ResolversTypes['AuthPayload'], ParentType, ContextType, RequireFields<MutationLoginWithFirebaseArgs, 'token'>>;
  logout?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  matching?: Resolver<ResolversTypes['CurrentAccountDTO'], ParentType, ContextType, RequireFields<MutationMatchingArgs, 'accountId'>>;
  rejectProposal?: Resolver<ResolversTypes['CallProposalDTO'], ParentType, ContextType, RequireFields<MutationRejectProposalArgs, 'input'>>;
  removeAccount?: Resolver<ResolversTypes['AccountDTO'], ParentType, ContextType, RequireFields<MutationRemoveAccountArgs, 'accountId'>>;
  removeAllConnectionsFromAccount?: Resolver<ResolversTypes['AccountDTO'], ParentType, ContextType, RequireFields<MutationRemoveAllConnectionsFromAccountArgs, 'accountId'>>;
  removeCall?: Resolver<ResolversTypes['CallDTO'], ParentType, ContextType, RequireFields<MutationRemoveCallArgs, 'id'>>;
  removeConnection?: Resolver<ResolversTypes['AccountDTO'], ParentType, ContextType, RequireFields<MutationRemoveConnectionArgs, 'accountId' | 'connectionId'>>;
  resetMyPassword?: Resolver<ResolversTypes['ResetPasswordResponse'], ParentType, ContextType, RequireFields<MutationResetMyPasswordArgs, 'email'>>;
  schedulePushNotification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSchedulePushNotificationArgs, 'FCM' | 'body' | 'title'>>;
  scheduleTenantIdPushNotification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationScheduleTenantIdPushNotificationArgs, 'body' | 'title'>>;
  sendPushNotification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendPushNotificationArgs, 'input'>>;
  setFCM?: Resolver<ResolversTypes['AccountDTO'], ParentType, ContextType, RequireFields<MutationSetFcmArgs, 'token'>>;
  startCall?: Resolver<ResolversTypes['CallDTO'], ParentType, ContextType, RequireFields<MutationStartCallArgs, 'id'>>;
  transferConnection?: Resolver<ResolversTypes['AccountDTO'], ParentType, ContextType, RequireFields<MutationTransferConnectionArgs, 'accountIdToBeTransferred' | 'newAccountId' | 'oldAccountId'>>;
  updateAccount?: Resolver<ResolversTypes['AccountDTO'], ParentType, ContextType, RequireFields<MutationUpdateAccountArgs, 'accountData' | 'id'>>;
  updateCall?: Resolver<ResolversTypes['CallDTO'], ParentType, ContextType, RequireFields<MutationUpdateCallArgs, 'id' | 'input'>>;
  updatePassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePasswordArgs, 'updatePasswordData'>>;
  updatePasswordWithCode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePasswordWithCodeArgs, 'code' | 'confirmation' | 'password'>>;
  updateTenant?: Resolver<ResolversTypes['TenantDTO'], ParentType, ContextType, RequireFields<MutationUpdateTenantArgs, 'tenantInput'>>;
  updateUser?: Resolver<ResolversTypes['UserDTO'], ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'id' | 'input'>>;
};

export type NewsResolvers<ContextType = any, ParentType extends ResolversParentTypes['News'] = ResolversParentTypes['News']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  linkURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sourceText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationTokensDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationTokensDTO'] = ResolversParentTypes['NotificationTokensDTO']> = {
  APNS?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  FCM?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleServerAuthToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ObjectIdScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ObjectId'], any> {
  name: 'ObjectId';
}

export type OnboardingStatusDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['OnboardingStatusDTO'] = ResolversParentTypes['OnboardingStatusDTO']> = {
  availability?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  match?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  personalInfoSubmitted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  quizCompleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginationMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginationMetadata'] = ResolversParentTypes['PaginationMetadata']> = {
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  limit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  page?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalPages?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrizePlacementResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrizePlacement'] = ResolversParentTypes['PrizePlacement']> = {
  predefinedCallId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prizeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrizesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Prizes'] = ResolversParentTypes['Prizes']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  img?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  buildAnalyticsReport?: Resolver<ResolversTypes['AnalyticsReportDTO'], ParentType, ContextType>;
  call?: Resolver<ResolversTypes['CallDTO'], ParentType, ContextType, RequireFields<QueryCallArgs, 'id'>>;
  calls?: Resolver<Array<ResolversTypes['CallDTO']>, ParentType, ContextType>;
  callsList?: Resolver<ResolversTypes['CallsListDTO'], ParentType, ContextType, Partial<QueryCallsListArgs>>;
  getAccount?: Resolver<ResolversTypes['AccountDTO'], ParentType, ContextType, RequireFields<QueryGetAccountArgs, 'id'>>;
  getAdminProfile?: Resolver<ResolversTypes['AdminDTO'], ParentType, ContextType, RequireFields<QueryGetAdminProfileArgs, 'id'>>;
  getCodesByMentorAccountId?: Resolver<Array<ResolversTypes['Code']>, ParentType, ContextType, RequireFields<QueryGetCodesByMentorAccountIdArgs, 'mentorAccountId' | 'tenantId'>>;
  getMentors?: Resolver<Array<ResolversTypes['AccountDTO']>, ParentType, ContextType>;
  getMyMentorAccount?: Resolver<ResolversTypes['AccountDTO'], ParentType, ContextType, RequireFields<QueryGetMyMentorAccountArgs, 'myAccountId'>>;
  getStudents?: Resolver<Array<ResolversTypes['AccountDTO']>, ParentType, ContextType>;
  getTenant?: Resolver<ResolversTypes['TenantDTO'], ParentType, ContextType, RequireFields<QueryGetTenantArgs, 'tenantKey'>>;
  getUserById?: Resolver<ResolversTypes['UserDTO'], ParentType, ContextType, RequireFields<QueryGetUserByIdArgs, 'id'>>;
  isValidCode?: Resolver<ResolversTypes['Code'], ParentType, ContextType, RequireFields<QueryIsValidCodeArgs, 'code'>>;
  me?: Resolver<ResolversTypes['MeDTO'], ParentType, ContextType>;
  mentorsAnalytics?: Resolver<Array<ResolversTypes['MentorsAnalyticsDTO']>, ParentType, ContextType>;
  metrics?: Resolver<Array<ResolversTypes['Metrics']>, ParentType, ContextType, Partial<QueryMetricsArgs>>;
  myCalls?: Resolver<ResolversTypes['CallsListDTO'], ParentType, ContextType, RequireFields<QueryMyCallsArgs, 'accountId'>>;
  proposalsByAppliant?: Resolver<Array<ResolversTypes['CallProposalDTO']>, ParentType, ContextType, RequireFields<QueryProposalsByAppliantArgs, 'appliantAccountId'>>;
  proposalsByCallId?: Resolver<Array<ResolversTypes['CallProposalDTO']>, ParentType, ContextType, RequireFields<QueryProposalsByCallIdArgs, 'callId'>>;
  proposalsByRequired?: Resolver<Array<ResolversTypes['CallProposalDTO']>, ParentType, ContextType, RequireFields<QueryProposalsByRequiredArgs, 'requiredAccountId'>>;
  screenViewsCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QueryScreenViewsCountArgs, 'screenName' | 'userId'>>;
  searchCodes?: Resolver<ResolversTypes['CodePaginatedResponse'], ParentType, ContextType, RequireFields<QuerySearchCodesArgs, 'limit' | 'page' | 'used'>>;
  studentsAnalytics?: Resolver<Array<ResolversTypes['StudentsAnalyticsDTO']>, ParentType, ContextType>;
  tenants?: Resolver<Array<ResolversTypes['TenantDTO']>, ParentType, ContextType>;
  tenantsAnalytics?: Resolver<ResolversTypes['TenantAnalyticsDTO'], ParentType, ContextType>;
  totalUsageTime?: Resolver<ResolversTypes['Float'], ParentType, ContextType, RequireFields<QueryTotalUsageTimeArgs, 'endDate' | 'startDate' | 'userType'>>;
  users?: Resolver<Array<ResolversTypes['UserDTO']>, ParentType, ContextType>;
};

export type QuestionDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionDTO'] = ResolversParentTypes['QuestionDTO']> = {
  answer?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuizDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizDTO'] = ResolversParentTypes['QuizDTO']> = {
  funnyQuestion1?: Resolver<ResolversTypes['QuestionDTO'], ParentType, ContextType>;
  funnyQuestion2?: Resolver<ResolversTypes['QuestionDTO'], ParentType, ContextType>;
  funnyQuestion3?: Resolver<ResolversTypes['QuestionDTO'], ParentType, ContextType>;
  personalQuestion1?: Resolver<ResolversTypes['QuestionDTO'], ParentType, ContextType>;
  personalQuestion2?: Resolver<ResolversTypes['QuestionDTO'], ParentType, ContextType>;
  personalQuestion3?: Resolver<ResolversTypes['QuestionDTO'], ParentType, ContextType>;
  questionOrderFunny?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  questionOrderPersonality?: Resolver<Array<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuizOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizOption'] = ResolversParentTypes['QuizOption']> = {
  image?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuizQuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizQuestion'] = ResolversParentTypes['QuizQuestion']> = {
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['QuizOption']>, ParentType, ContextType>;
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  weight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuizResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuizResponse'] = ResolversParentTypes['QuizResponse']> = {
  answers?: Resolver<Maybe<Array<ResolversTypes['Answer']>>, ParentType, ContextType>;
  totalQuestions?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResetPasswordResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResetPasswordResponse'] = ResolversParentTypes['ResetPasswordResponse']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SduiResolvers<ContextType = any, ParentType extends ResolversParentTypes['SDUI'] = ResolversParentTypes['SDUI']> = {
  university?: Resolver<ResolversTypes['University'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendBirdUserDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendBirdUserDTO'] = ResolversParentTypes['SendBirdUserDTO']> = {
  accessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlotDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['SlotDTO'] = ResolversParentTypes['SlotDTO']> = {
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Status']>, ParentType, ContextType>;
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StudentsAnalyticsDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['StudentsAnalyticsDTO'] = ResolversParentTypes['StudentsAnalyticsDTO']> = {
  accountId?: Resolver<Maybe<ResolversTypes['ObjectId']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastActivityAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastCallAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastSyncAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  mentor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mentorId?: Resolver<Maybe<ResolversTypes['ObjectId']>, ParentType, ContextType>;
  progress?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalDoneCalls?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalUpcomingCalls?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['ObjectId']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantAnalyticsDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['TenantAnalyticsDTO'] = ResolversParentTypes['TenantAnalyticsDTO']> = {
  averageCallsDuration?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lastSyncAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  nMessages?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalActiveUsersLast14Days?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalCallsDuration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalDoneCalls?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalStudentsOnboarded?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalUpcomingCalls?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantConfigDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['TenantConfigDTO'] = ResolversParentTypes['TenantConfigDTO']> = {
  GAC?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  appVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  callsTitlesTopics?: Resolver<Maybe<Array<ResolversTypes['CallTitleTopic']>>, ParentType, ContextType>;
  googleMapsApiKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  googleWebClientId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  majorsList?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  maxStudentsPerMentor?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  prizePlacement?: Resolver<Maybe<Array<ResolversTypes['PrizePlacement']>>, ParentType, ContextType>;
  prizes?: Resolver<Maybe<Array<ResolversTypes['Prizes']>>, ParentType, ContextType>;
  quiz?: Resolver<Maybe<Array<ResolversTypes['QuizQuestion']>>, ParentType, ContextType>;
  sendbirdApiToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sendbirdAppId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  testEmails?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['TenantDTO'] = ResolversParentTypes['TenantDTO']> = {
  SDUI?: Resolver<Maybe<ResolversTypes['SDUI']>, ParentType, ContextType>;
  configs?: Resolver<Maybe<ResolversTypes['TenantConfigDTO']>, ParentType, ContextType>;
  domain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  storageBucket?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TopicResolvers<ContextType = any, ParentType extends ResolversParentTypes['Topic'] = ResolversParentTypes['Topic']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityResolvers<ContextType = any, ParentType extends ResolversParentTypes['University'] = ResolversParentTypes['University']> = {
  brandingDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  coatOfArms?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  commonApplication?: Resolver<ResolversTypes['CommonApplication'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  events?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType>;
  latestNews?: Resolver<Array<ResolversTypes['News']>, ParentType, ContextType>;
  logo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profileImage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videos?: Resolver<Array<ResolversTypes['VideoDTO']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserDTO'] = ResolversParentTypes['UserDTO']> = {
  accessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  accountsLinked?: Resolver<Array<ResolversTypes['AccountLinkDTO']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  emailVerifiedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firebaseUid?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  password?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  passwordChanged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  passwordChangedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  passwordReset?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  passwordResetAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoDTO'] = ResolversParentTypes['VideoDTO']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AccountConnetionsPopulated?: AccountConnetionsPopulatedResolvers<ContextType>;
  AccountDTO?: AccountDtoResolvers<ContextType>;
  AccountJourneyDTO?: AccountJourneyDtoResolvers<ContextType>;
  AccountLinkDTO?: AccountLinkDtoResolvers<ContextType>;
  AccountLocationDTO?: AccountLocationDtoResolvers<ContextType>;
  AccountProfileDTO?: AccountProfileDtoResolvers<ContextType>;
  AdminAuthPayload?: AdminAuthPayloadResolvers<ContextType>;
  AdminDTO?: AdminDtoResolvers<ContextType>;
  AnalyticsReportDTO?: AnalyticsReportDtoResolvers<ContextType>;
  Answer?: AnswerResolvers<ContextType>;
  AuthPayload?: AuthPayloadResolvers<ContextType>;
  AvailabilityDTO?: AvailabilityDtoResolvers<ContextType>;
  CallDTO?: CallDtoResolvers<ContextType>;
  CallDetailsDto?: CallDetailsDtoResolvers<ContextType>;
  CallProposalDTO?: CallProposalDtoResolvers<ContextType>;
  CallTitleTopic?: CallTitleTopicResolvers<ContextType>;
  CallsListDTO?: CallsListDtoResolvers<ContextType>;
  Code?: CodeResolvers<ContextType>;
  CodePaginatedResponse?: CodePaginatedResponseResolvers<ContextType>;
  CommonApplication?: CommonApplicationResolvers<ContextType>;
  ConnectionDTO?: ConnectionDtoResolvers<ContextType>;
  CurrentAccountDTO?: CurrentAccountDtoResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Event?: EventResolvers<ContextType>;
  Front?: FrontResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  MeDTO?: MeDtoResolvers<ContextType>;
  MentorsAnalyticsDTO?: MentorsAnalyticsDtoResolvers<ContextType>;
  Metrics?: MetricsResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  News?: NewsResolvers<ContextType>;
  NotificationTokensDTO?: NotificationTokensDtoResolvers<ContextType>;
  ObjectId?: GraphQLScalarType;
  OnboardingStatusDTO?: OnboardingStatusDtoResolvers<ContextType>;
  PaginationMetadata?: PaginationMetadataResolvers<ContextType>;
  PrizePlacement?: PrizePlacementResolvers<ContextType>;
  Prizes?: PrizesResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  QuestionDTO?: QuestionDtoResolvers<ContextType>;
  QuizDTO?: QuizDtoResolvers<ContextType>;
  QuizOption?: QuizOptionResolvers<ContextType>;
  QuizQuestion?: QuizQuestionResolvers<ContextType>;
  QuizResponse?: QuizResponseResolvers<ContextType>;
  ResetPasswordResponse?: ResetPasswordResponseResolvers<ContextType>;
  SDUI?: SduiResolvers<ContextType>;
  SendBirdUserDTO?: SendBirdUserDtoResolvers<ContextType>;
  SlotDTO?: SlotDtoResolvers<ContextType>;
  StudentsAnalyticsDTO?: StudentsAnalyticsDtoResolvers<ContextType>;
  TenantAnalyticsDTO?: TenantAnalyticsDtoResolvers<ContextType>;
  TenantConfigDTO?: TenantConfigDtoResolvers<ContextType>;
  TenantDTO?: TenantDtoResolvers<ContextType>;
  Topic?: TopicResolvers<ContextType>;
  University?: UniversityResolvers<ContextType>;
  UserDTO?: UserDtoResolvers<ContextType>;
  VideoDTO?: VideoDtoResolvers<ContextType>;
};

