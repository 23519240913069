import { createContext, useContext, useState } from "react";

interface SyncDataContextType {
  setIsSyncing: (isSyncing: boolean) => void;
  isSyncing: boolean;
  lastSync: string | null;
}

const SyncDataContext = createContext<SyncDataContextType | undefined>(undefined);

export const SyncDataProvider = ({ children }: { children: React.ReactNode }) => {
  const [isSyncing, setIsSyncing] = useState(false);
  const [lastSync, setLastSync] = useState<string | null>(null);

  // useEffect(() => {
  //   setIsSyncing(loading);
  // }, [loading]);

  // useEffect(() => {
  //   if (data?.buildAnalyticsReport?.sucess && data?.buildAnalyticsReport?.documents > 0) {
  //     setLastSync(new Date().toISOString());
  //   }
  // }, [data]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (shouldSync()) {
  //       refetch();
  //     }
  //   }, 60 * 1000 * 5); // 5 minutes

  //   return () => clearInterval(interval); // Clean up the interval when component unmounts
  // }, [refetch]);

  return (
    <SyncDataContext.Provider value={{ setIsSyncing, isSyncing, lastSync }}>
      {children}
    </SyncDataContext.Provider>
  );
};

export const useSyncDataContext = () => {
  return useContext(SyncDataContext);
};
