import { ApolloError } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";
import { QUERY_GET_CODES_BY_TENANT } from "graphql/code.graphql";
import { Code } from "types/graphql-types";
import { useTenantQuery } from "./useTenantQuery";

interface ICodesByMentorAccountIdData {
  data?: { searchCodes: { data: Code[] } };
  loading: boolean;
  refetch: () => void;
  error?: ApolloError | undefined;
}

export interface CodeQueryVariables {
  limit?: number;
  page?: number | null;
  email?: string | null;
  used?: boolean | null;
  forcedWith?: string | null;
  code?: string | null;
  tenantId?: string | null;
}

export const useGetCodesByTenant = (queryVars?: CodeQueryVariables): ICodesByMentorAccountIdData => {
  const { user } = useAuth();
  return useTenantQuery<
    { searchCodes: { data: Code[] } }, 
    { tenantId: string } & CodeQueryVariables
  >(
    QUERY_GET_CODES_BY_TENANT,
    {
      variables: {
        tenantId: user?.tenantId,
        limit: queryVars?.limit ?? undefined,
        page: queryVars?.page ?? null,
        email: queryVars?.email ?? null,
        used: Boolean(queryVars?.used) ?? null,
        forcedWith: queryVars?.forcedWith ?? null,
        code: queryVars?.code ?? null,
      },
    }
  );
};