import React, { useState } from 'react'
import {
  Box,
  VStack,
  Heading,
  Text,
  Input,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  IconButton,
  Flex,
} from '@chakra-ui/react'
import { AddIcon, DeleteIcon } from '@chakra-ui/icons'

// This would typically come from a prop or context, but for this example, we'll use a placeholder
const initialConfig = {
  maxStudentsPerMentor: 10,
  sendbirdAppId: "52CE65AA-82CE-4325-9AC6-52C2D5387BA9",
  appVersion: "1.92.1",
  callsTitlesTopics: [
    {
      call: "Building Trust",
      id: 1,
      front: {
        title: "Call 1",
        subtitle: "Ready to Shape Your Future? 🚀✨",
        topics: [
          {
            id: "1",
            title: "🎯 Crafting Your Personal Game Plan"
          },
          // ... other topics
        ]
      },
      videos: [
        {
          id: 1,
          url: "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/preCallVideos%2F%231%2FE2D%20Call%20%231%20Trailer.mp4?alt=media&token=9ed58827-ae71-4ca9-a2e2-0488ea443099"
        }
      ]
    },
    // ... other calls
  ],
  googleMapsApiKey: "AIzaSyAhpeaKL4QwYz3K5V8GL4cVhL0QOy5eyJM",
  googleWebClientId: "851302758732-m0igcrkkmrnkq56gqu884bf0j1g0f8rk.apps.googleusercontent.com",
  sendbirdApiToken: "474e5c3d4c910cbf616afe95304164e4f1d32fe1",
  testEmails: [
    "jdmaydan@gmail.com",
    "jonathan@ed2dream.com",
    // ... other emails
  ],
  GAC: "G-43LJJZ6LCY",
  prizePlacement: [
    {
      predefinedCallId: 1,
      prizeId: 0
    },
    // ... other prize placements
  ],
  prizes: [
    {
      id: 0,
      title: "A really nice cap!",
      img: ""
    },
    // ... other prizes
  ],
  majorsList: [
    "Anthropology",
    "Art History",
    // ... other majors
  ],
  quiz: [
    {
      type: "multipleChoice",
      category: "personal",
      question: "Did either of your parents graduate from a 4-year college or university?",
      options: [
        {
          title: "Yes",
          image: ""
        },
        {
          title: "No",
          image: ""
        }
      ],
      weight: 1
    },
    // ... other quiz questions
  ]
}

export default function ConfigEditor() {
  const [config, setConfig] = useState(initialConfig)

  const handleChange = (path: string, value: any) => {
    setConfig(prevConfig => {
      const newConfig = JSON.parse(JSON.stringify(prevConfig))
      let current = newConfig
      const keys = path.split('.')
      const lastKey = keys.pop()
      for (let key of keys) {
        if (!current[key]) current[key] = {}
        current = current[key]
      }
      current[lastKey] = value
      return newConfig
    })
  }

  const handleArrayChange = (path: string, index: number, value: any) => {
    setConfig(prevConfig => {
      const newConfig = JSON.parse(JSON.stringify(prevConfig))
      let current = newConfig
      const keys = path.split('.')
      for (let key of keys) {
        if (!current[key]) current[key] = []
        current = current[key]
      }
      current[index] = value
      return newConfig
    })
  }

  const handleArrayAdd = (path: string) => {
    setConfig(prevConfig => {
      const newConfig = JSON.parse(JSON.stringify(prevConfig))
      let current = newConfig
      const keys = path.split('.')
      for (let key of keys) {
        if (!current[key]) current[key] = []
        current = current[key]
      }
      current.push('')
      return newConfig
    })
  }

  const handleArrayRemove = (path: string, index: number  ) => {
    setConfig(prevConfig => {
      const newConfig = JSON.parse(JSON.stringify(prevConfig))
      let current = newConfig
      const keys = path.split('.')
      for (let key of keys) {
        if (!current[key]) current[key] = []
        current = current[key]
      }
      current.splice(index, 1)
      return newConfig
    })
  }

  const renderEditableField = (label: string, path: string, value: any) => {
    if (typeof value === 'string') {
      return (
        <Box mb={4}>
          <Text fontWeight="bold">{label}</Text>
          <Input
            value={value}
            onChange={(e) => handleChange(path, e.target.value)}
            size="sm"
          />
        </Box>
      )
    } else if (typeof value === 'number') {
      return (
        <Box mb={4}>
          <Text fontWeight="bold">{label}</Text>
          <NumberInput
            value={value}
            onChange={(valueString) => handleChange(path, Number(valueString))}
            size="sm"
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
      )
    } else if (Array.isArray(value)) {
      return (
        <Box mb={4}>
          <Text fontWeight="bold">{label}</Text>
          {value.map((item, index) => (
            <Flex key={index} mb={2}>
              <Input
                value={item}
                onChange={(e) => handleArrayChange(path, index, e.target.value)}
                size="sm"
              />
              <IconButton
                aria-label="Remove item"
                icon={<DeleteIcon />}
                onClick={() => handleArrayRemove(path, index)}
                ml={2}
                size="sm"
              />
            </Flex>
          ))}
          <Button
            leftIcon={<AddIcon />}
            onClick={() => handleArrayAdd(path)}
            size="sm"
            mt={2}
          >
            Add Item
          </Button>
        </Box>
      )
    } else {
      return (
        <Box mb={4}>
          <Text fontWeight="bold">{label}</Text>
          <Text color="gray.500">Complex data (not directly editable)</Text>
        </Box>
      )
    }
  }

  return (
    <Box p={5}>
      <VStack spacing={5} align="stretch">
        <Heading>Config Editor</Heading>
        
        <Accordion allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Basic Settings
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {renderEditableField("Max Students Per Mentor", "maxStudentsPerMentor", config.maxStudentsPerMentor)}
              {renderEditableField("Sendbird App ID", "sendbirdAppId", config.sendbirdAppId)}
              {renderEditableField("App Version", "appVersion", config.appVersion)}
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  API Keys
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {renderEditableField("Google Maps API Key", "googleMapsApiKey", config.googleMapsApiKey)}
              {renderEditableField("Google Web Client ID", "googleWebClientId", config.googleWebClientId)}
              {renderEditableField("Sendbird API Token", "sendbirdApiToken", config.sendbirdApiToken)}
              {renderEditableField("GAC", "GAC", config.GAC)}
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Test Emails
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {renderEditableField("Test Emails", "testEmails", config.testEmails)}
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Majors List
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {renderEditableField("Majors", "majorsList", config.majorsList)}
            </AccordionPanel>
          </AccordionItem>

          {/* Add more AccordionItems for other complex nested structures like callsTitlesTopics, prizePlacement, prizes, and quiz */}
        </Accordion>

        <SimpleGrid columns={2} spacing={5}>
          <Button colorScheme="blue">Save Changes</Button>
          <Button variant="outline">Reset</Button>
        </SimpleGrid>
      </VStack>
    </Box>
  )
}