import { gql } from "@apollo/client";

export const MUTATION_LOGIN = gql`
  mutation Login($input: LoginInput!) {
    adminSigningIn(input: $input) {
      admin {
        tenantId
        email
        displayName
        adminLevel
      }
      accessToken
    }
  }
`;

export const QUERY_GET_ME = gql`
  query Query {
    getAdminProfile {
      tenantId
      email
      displayName
      adminLevel
      profilePhotoUrl
      createdAt
      updatedAt
    }
  }
`;
