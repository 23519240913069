import { ApolloError } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";
import { QUERY_GET_CODES_BY_MENTOR_ACCOUNT_ID } from "graphql/code.graphql";
import { Code } from "types/graphql-types";
import { useTenantQuery } from "./useTenantQuery";

interface ICodesByMentorAccountIdData {
  data?: { getCodesByMentorAccountId: Code[] };
  loading: boolean;
  refetch: () => void;
  error?: ApolloError | undefined;
}

export const useCodesByMentorAccountId = (mentorAccountId: string): ICodesByMentorAccountIdData => {
  const { user } = useAuth();
  return useTenantQuery<{ getCodesByMentorAccountId: Code[] }, { mentorAccountId: string; tenantId: string }>(
    QUERY_GET_CODES_BY_MENTOR_ACCOUNT_ID,
    {
      variables: { mentorAccountId: mentorAccountId, tenantId: user?.tenantId },
    }
  );
};