import { Card as BoardCard, KanbanBoard, UncontrolledBoard } from "@caldwell619/react-kanban";
import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { renderColumnHeader } from "./components/ColumnHeader";
import Card from "components/card/Card";
import { AccountDto, ConnectionDto } from "types/graphql-types";
import { useState, useEffect } from "react";
import { renderCard } from "./components/KanBanTaskCard";
import { useActivityIndicator } from "hooks/useActivityIndicator";
import { useMentorsData } from "hooks/useMentorsData";
import { useStudentsData } from "hooks/useStudentsData";
import { useAddConnection } from "hooks/useAddConnection";
import { useTransferConnection } from "hooks/useTransferConnection";
import { useRemoveConnection } from "hooks/useRemoveConnection";

function MentorshipCentered(){
  const [isLoading, setIsLoading] = useState(false)
  const { setIsLoading: setLoadingIndicator, isLoading: loadingIndicator } = useActivityIndicator();
  const { data: mentorsData, loading: mentorsLoading, refetch: refetchMentors } = useMentorsData()
  const { data: studentsData, loading: studentsLoading, refetch: refetchStudents } = useStudentsData()
  const [mentors, setMentors] = useState<AccountDto[]>(mentorsData?.getMentors ?? [])
  const [students, setStudents] = useState<AccountDto[]>(studentsData?.getStudents ?? [])
  const [addConnection, { loading: addConnectionLoading }] = useAddConnection()
  const [transferConnection, { loading: transferConnectionLoading }] = useTransferConnection()
  const [removeConnection] = useRemoveConnection()

  const _buildCoulumns = () => {
    const unmatchedStudents: AccountDto[] = []

    const resultColumns = mentors.map((mentor: AccountDto) => {
      const connections = mentor.accountConnections?.map((connection: ConnectionDto) => connection?.connectedWith?.id)

      const _students = students.filter((student: AccountDto) => connections?.includes(student.id)) ?? []

      const column = {
        id: mentor.id,
        title: mentor.profile?.displayName,
        cards: _students.map((student: AccountDto) => ({
          id: student.id,
          title: student.profile?.displayName,
          photoURL: student.profile?.photoURL,
          description: student.profile?.description,
        })),
      }
      return column
    }) ?? []

    const studentsWithNoConnections = students.filter(student => !(Boolean(student?.mentorId)) || student.mentorId === null) ?? []
    studentsWithNoConnections.forEach(student => unmatchedStudents.push(student))
    resultColumns.unshift({
      id: "unmatched",
      title: "Unmatched",
      cards: unmatchedStudents.map((student: AccountDto) => ({
        id: student.id,
        title: student.profile.displayName,
        description: student.profile.description || '', // Use a string instead of JSX
        photoURL: student?.profile?.photoURL,
      })),
    })
    return resultColumns;
  }

  const renderBoard = () => {
    const resultColumns = _buildCoulumns()
    return {
      columns: resultColumns
    }
  } 

  useEffect(() => {
    if(!studentsLoading) {
      setStudents([...studentsData.getStudents])
    }
    if(!mentorsLoading) {
      setMentors([...mentorsData.getMentors])
    }
  }, [studentsData, mentorsData, studentsLoading, mentorsLoading])

  const handleCardMove: any = (_board: KanbanBoard<BoardCard>, _card: BoardCard, source: any, destination: any) => {
    if(destination.toColumnId === "unmatched") {
      return removeConnection({
        variables: {
          accountId: _card.id,
          connectionId: source.fromColumnId
        }
      })
      .then(() => Promise.all([setIsLoading(true), refetchMentors(), refetchStudents()]))
      .then(() => {
        return setIsLoading(false)
      })
      .catch((error: any) => {
        console.error("Error transferring connection", error)
      })
    }

    if(source.fromColumnId === "unmatched") {
      return addConnection({
        variables: {
          accountId: _card.id,
          connectionId: destination.toColumnId,
        }
      })
      .then(() => Promise.all([setIsLoading(true), refetchMentors(), refetchStudents()]))
      .then(() => {
        return setIsLoading(false)
      })
      .catch((error: any) => {
        throw new Error("Error adding connection", error)
      })
    }

    if(source.fromColumnId && destination.toColumnId && _card.id) {
      transferConnection({
        variables: {
          oldAccountId: source.fromColumnId, 
          newAccountId: destination.toColumnId, 
          accountIdToBeTransferred: _card.id,
        }
      })
      .then(() => Promise.all([setIsLoading(true), refetchMentors(), refetchStudents()]))
      .then(() => {
        return setIsLoading(false)
      })
      .catch((error: any) => {
        throw new Error("Error transfering connection", error)
      })
    }
  }

  useEffect(() => {
    const acquiring = [mentorsLoading, studentsLoading, addConnectionLoading, transferConnectionLoading, isLoading].some(Boolean)
    if(acquiring) {
      setLoadingIndicator(true)
    } else {
      setLoadingIndicator(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentorsLoading, studentsLoading, addConnectionLoading, transferConnectionLoading, isLoading])
 
  if(loadingIndicator) {
    return <div>Loading...</div>
  }

  const _board = (renderBoard() ?? null) as KanbanBoard<any>
  return (
    loadingIndicator ? <div>Loading...</div> : (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} >
      <Card py="15px">
        <Flex
          direction={"column"}
        >
          <Box>
            <Text fontSize={"2xl"}>
              Controller <small>board</small>
            </Text>
          </Box>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
            <UncontrolledBoard 
              initialBoard={{ ..._board }} 
              renderColumnHeader={renderColumnHeader}
              renderCard={renderCard}
              onCardDragEnd={handleCardMove}
              disableColumnDrag
            />
          </SimpleGrid>
        </Flex>
      </Card>
    </Box>
    )
  );
}

export default MentorshipCentered;
