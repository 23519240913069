import { GET_MENTORS_ANALYTICS_DATA } from "graphql/data.graphql";
import { MentorsAnalyticsDto } from "types/graphql-types";
import { useTenantQuery } from "./useTenantQuery";

interface IMentorsData {
  data: { mentorsAnalytics: MentorsAnalyticsDto[] };
  loading: boolean;
}

export const useMentorsAnalyticsData = (): IMentorsData => {
  return useTenantQuery(GET_MENTORS_ANALYTICS_DATA);
};