import { gql } from "@apollo/client";

export const QUERY_GET_MENTORS = gql`
query GetMentors {
  getMentors {
    id
    userId
    userType
    notificationTokens {
      FCM
    }
    accountConnections {
      connectedWith {
        id
      }
    }
    tenantId
    profile {
     displayName 
    }
    disabledAt
    createdAt
    updatedAt
  }
}
`;

export const QUERY_GET_STUDENTS = gql`
query GetStudents {
  getStudents {
    id
    userId
    userType
    notificationTokens {
      FCM
    }
    tenantId
    mentorId
    profile {
     displayName
     photoURL
    }
    disabledAt
    createdAt
    updatedAt
  }
}
`;