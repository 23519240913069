import { useMutation, QueryHookOptions } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";
import {  MUTATION_TRANSFER_CONNECTION } from "graphql/account-connections.graphql";

export const useTransferConnection = <TData, TVariables>(
  options?: Omit<QueryHookOptions<TData, TVariables>, 'context'>
) => {
  const { user } = useAuth();

  return useMutation<TData, TVariables>(MUTATION_TRANSFER_CONNECTION, {
    context: {
      headers: {
        "x-tenant-id": user?.tenantId,
      },
    },
    ...options,
    fetchPolicy: 'no-cache'
  });
};