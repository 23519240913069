import { gql } from "@apollo/client";

export const QUERY_GET_GET_ACCOUNT = gql`
query GetAccount($getAccountId: String!) {
  getAccount(id: $getAccountId) {
    id
    userId
    userType
    notificationTokens {
      FCM
      APNS
    }
    accountConnections {
      connectionType
      connectedWith {
        id
      }
    }
    availability {
      day
      slots {
        from
        to
        status
      }
    }
    onboardingStatus {
      availability
      match
      quizCompleted
      personalInfoSubmitted
    }
    tenantId
    mentorId
    profile {
      description
      displayName
      phoneNumber
      photoURL
      gradeLevel
      highSchool
      major
      majorYear
      college
      minor
      ethnicity
      intendedMajor
      location {
        latitude
        longitude
      }
      videos {
        id
        url
      }
      interests
    }
    rating
    disabledAt
    createdAt
    updatedAt
  }
}
`;