import { gql } from "@apollo/client";

export const QUERY_GET_CODES_BY_MENTOR_ACCOUNT_ID  = gql`
  query GetCodesByMentorAccountId($mentorAccountId: String!, $tenantId: String! ) {
    getCodesByMentorAccountId(mentorAccountId: $mentorAccountId, tenantId: $tenantId) {
      code
      email
      forceConnectionWith
      used
    }
  }
`

export const QUERY_GET_CODES_BY_TENANT = gql`
  query SearchCodes($limit: Float, $page: Float, $email: String, $used: Boolean, $forcedWith: String, $code: String, $tenantId: String) {
  searchCodes(limit: $limit, page: $page, email: $email, used: $used, forcedWith: $forcedWith, code: $code, tenantId: $tenantId) {
    data {
      id
      code
      tenantId
      forceConnectionWith
      email
      used
    }
    metadata {
      total
      page
      limit
      totalPages
      hasNextPage
      hasPreviousPage
    }
  }
}
`

export const CREATE_CODE_MUTATION = gql`
mutation CreateCode($createCodeInput: CreateCodeInput!) {
  createCode(createCodeInput: $createCodeInput) {
    id
    code
    tenantId
    forceConnectionWith
    email
    used
  }
}
`

export const BULK_CREATE_CODE_MUTATION = gql`
mutation BulkCreateCode($bulkCreateCodeInput: BulkCreateCodeInput!) {
  bulkCreateCode(bulkCreateCodeInput: $bulkCreateCodeInput) {
    code
  }
}
`


export default QUERY_GET_CODES_BY_MENTOR_ACCOUNT_ID;