import {
  Badge,
  Box,
  Flex,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from "@tanstack/react-table";
import Card from "components/card/Card";
import { toLocalizedDateString } from "components/LocalDate";
import { useMentorsData } from "hooks/useMentorsData";
import * as React from "react";
import { RowObjStudents } from "../variables/tableDataComplex";
import DetailsModal from "./DetailsModal";
import { dateColumns } from "./utils";

const columnHelper = createColumnHelper<RowObjStudents>();

const StudentsTable: React.FC<{ tableData: RowObjStudents[] }> = ({
  tableData,
}) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const defaultData = tableData;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedStudent, setSelectedStudent] =
    React.useState<RowObjStudents | null>(null);
  const { data: mentorsData } = useMentorsData()

  const handleRowClick = (student: RowObjStudents) => {
    setSelectedStudent(student);
    onOpen();
  };

  const columns = [
    columnHelper.accessor("displayName", {
      id: "displayName",
      header: () => renderHeader("NAME"),
      cell: (info) => renderTextCell(info, textColor),
    }),
    columnHelper.accessor("mentor", {
      id: "mentor",
      header: () => renderHeader("MENTOR"),
      cell: (info) => {
        const mentor = mentorsData?.getMentors?.find(m => m.id === info?.getValue())
        return mentor?.profile?.displayName || 'N/A';
      },
    }),
    columnHelper.accessor("totalUpcomingCalls", {
      id: "totalUpcomingCalls",
      header: () => renderHeader("Upcoming calls"),
      cell: (info) => renderTextCell(info, textColor),
    }),
    columnHelper.accessor("totalDoneCalls", {
      id: "totalDoneCalls",
      header: () => renderHeader("Done calls"),
      cell: (info) => renderTextCell(info, textColor),
    }),
    columnHelper.accessor("lastActivityAt", {
      id: "lastActivityAt",
      header: () => renderHeader("last Access"),
      cell: (info) => {
        const existsValues =
          info.row._valuesCache[info.column.id] !== undefined &&
          info.row._valuesCache[info.column.id] !== null &&
          info.row._valuesCache[info.column.id] !== "undefined" &&
          info.row._valuesCache[info.column.id] !== "null" &&
          info.row._valuesCache[info.column.id] !== "";
        if (dateColumns.includes(info.column.id) && existsValues) {
          info = {
            ...info,
            getValue: () =>
              toLocalizedDateString(
                info.row._valuesCache[info.column.id] as unknown as string
              ) as unknown as any,
          };
        }
        return renderTextCell(info, textColor);
      },
    }),
    columnHelper.accessor("lastCallAt", {
      id: "lastCallAt",
      header: () => renderHeader("Last Call"),
      cell: (info) => {
        const existsValues =
          info.row._valuesCache[info.column.id] !== undefined &&
          info.row._valuesCache[info.column.id] !== null &&
          info.row._valuesCache[info.column.id] !== "undefined" &&
          info.row._valuesCache[info.column.id] !== "null" &&
          info.row._valuesCache[info.column.id] !== "";
        if (dateColumns.includes(info.column.id) && existsValues) {
          const _value = existsValues
            ? (toLocalizedDateString(
                info.row._valuesCache[info.column.id] as unknown as string
              ) as unknown as any)
            : ("--" as any);
          info = {
            ...info,
            getValue: () => _value as unknown as string as unknown as any,
          };
        }
        return renderTextCell(info, textColor);
      },
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => renderHeader("STATUS"),
      cell: (info) => renderTextCell(info, textColor),
    }),
  ];

  const [data] = React.useState<RowObjStudents[]>(() => [...defaultData]);

  const table = useReactTable({
    data,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <>
      <Card
        flexDirection="column"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
          <HStack>
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Students
            </Text>
            <Badge colorScheme="blue">{tableData?.length}</Badge>
          </HStack>
          {/* <Menu /> */}
        </Flex>
        <Box maxH="500px" overflowY="scroll" px="1">
          {" "}
          {/* Add padding to avoid cutoff */}
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead position="sticky" top="0" bg="white" zIndex={40}>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{ asc: "", desc: "" }[
                          header.column.getIsSorted() as string
                        ] ?? null}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr
                  key={row.id}
                  onClick={() => handleRowClick(row.original)}
                  cursor="pointer"
                  _hover={{
                    outline: "2px solid",
                    outlineColor: "blue.400",
                    borderRadius: "lg",
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Card>

      <DetailsModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        selectedStudent={selectedStudent}
      />
    </>
  );
};

const renderHeader = (title: string) => (
  <Text
    justifyContent="space-between"
    align="center"
    fontSize={{ sm: "10px", lg: "12px" }}
    color="gray.400"
  >
    {title}
  </Text>
);

const renderTextCell = (info: any, textColor: string) => (
  <Flex align="center">
    <Text color={textColor} fontSize="sm" fontWeight="700">
      {info.getValue()}
    </Text>
  </Flex>
);

export default StudentsTable;
