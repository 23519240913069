import { FC, useRef } from "react";
import { Text, Avatar, Box, IconButton, Button, HStack, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, useDisclosure } from "@chakra-ui/react";
import {
  Card as BoardCard,
  UncontrolledBoardProps,
} from "@caldwell619/react-kanban";
import Card from "components/card/Card";
import { CardContent, CardHeader } from "components/ui/card";
import { DeleteIcon } from "@chakra-ui/icons";
import { useDeleteUser } from "hooks/useDeleteUser";
import { useStudentsData } from "hooks/useStudentsData";

interface TaskCardProps {
  title?: string;
  description?: string;
  id: number | string;
  photoURL?: string;
}

export const KanBanTaskCard: FC<BoardCard> = (
  { title, description, id, photoURL }: TaskCardProps = {
    title: "John Doe",
    description: "Implement new feature",
    id: "TASK-123",
    photoURL: "",
  }
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const [deleteUser] = useDeleteUser();
  const { refetch: refetchStudents } = useStudentsData()

  const handleDelete = async () => {
    await deleteUser({ variables: { deleteUserId: id } });
    onClose();
    refetchStudents();
  }

  return (
    <Box m={2}>
      <Card width="300px" boxShadow="md">
        <CardHeader>
          <HStack>
            <Avatar size="sm" name={`Assignee ${id}`} src={photoURL} />
            <Text fontSize="xs" color="gray.500">{title}</Text>
          </HStack>
        </CardHeader>
          <CardContent>
            <HStack justifyContent="space-between">
              <Text fontSize="xs" color="gray.500">{id}</Text>
              <IconButton size={"xs"} icon={<DeleteIcon />} onClick={onOpen} aria-label="Remove card" className="align-self-end"/>
            </HStack>
        </CardContent>
        <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Student
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={() => handleDelete()} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Card>
    </Box>
  );
};

export const renderCard: UncontrolledBoardProps<BoardCard>["renderCard"] = (
  card
) => {
  return <KanBanTaskCard {...card} />;
};
