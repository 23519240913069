import { gql } from "@apollo/client";

export const GET_TENANTS = gql`
query Tenants {
  tenants {
    id
    key
    name
    storageBucket
    domain
    SDUI {
      university {
        coatOfArms
        name
        logo
        description
        profileImage
        videos {
          id
          url
        }
        brandingDescription
        latestNews {
          id
          linkURL
          imageUrl
          sourceText
          title
        }
        events {
          id
          imageUrl
          title
          dateTime
          location
          linkURL
        }
      }
    }
    configs {
      maxStudentsPerMentor
      sendbirdAppId
      appVersion
      callsTitlesTopics {
        call
        id
        front {
          title
          subtitle
          topics {
            id
            title
          }
        }
        videos {
          id
          url
        }
      }
      googleMapsApiKey
      googleWebClientId
      GAC
      sendbirdApiToken
      testEmails
      majorsList
      prizePlacement {
        predefinedCallId
        prizeId
      }
      prizes {
        id
        title
        img
        description
      }
    }
  }
}
`;