import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import React from 'react';

interface LocalDateProps {
  isoDate: string;
  formatString?: string;
}

export const toLocalizedDateString = (isoDate: string, formatString = 'PPpp') => {
  return format(new Date(isoDate), formatString, { locale: enUS });
}

const LocalDate: React.FC<LocalDateProps> = ({ isoDate, formatString = 'PPpp' }) => {
  const formattedDate = toLocalizedDateString(isoDate, formatString);
  
  return <span>{formattedDate}</span>;
};

export default LocalDate;
