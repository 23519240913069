import { QueryResult, useQuery } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";
import { UPCOMING_CALLS_QUERY } from "graphql/data.graphql";
import { useEffect, useState } from "react";

interface UpcomingCallsData {
  myCalls: {
    upcoming: any[];
  };
}

interface UpcomingCallsResponse {
  loading: boolean;
  error: Error | undefined;
  calls: any[];
  refetch: QueryResult<UpcomingCallsData, UpcomingCallsVars>["refetch"];
}

interface UpcomingCallsVars {
  accountId: string;
}

export const useUpcomingCalls = (accountId: string): UpcomingCallsResponse => {
  const [upcomingCalls, setUpcomingCalls] = useState([]);
  const { user } = useAuth();

  const { loading, error, data, refetch } = useQuery<
    UpcomingCallsData,
    UpcomingCallsVars
  >(UPCOMING_CALLS_QUERY, {
    skip: !accountId,
    variables: { accountId },
    context: {
      headers: {
        "x-tenant-id": user?.tenantId,
      },
    },
  });

  useEffect(() => {
    setUpcomingCalls([]);
    refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  useEffect(() => {
    if (data?.myCalls?.upcoming?.length > 0) {
      setUpcomingCalls([...data.myCalls.upcoming]);
    } else {
      setUpcomingCalls([]);
    }
  }, [data, loading, error, accountId]);


  return { loading, error, calls: upcomingCalls, refetch };
};
