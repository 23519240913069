// Chakra imports
import {
  Box,
  Portal,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin";
// Layout components
import Navbar from "components/navbar/NavbarAdmin";
import ProtectedRoute from "components/ProtectedRoute";
import Sidebar from "components/sidebar/Sidebar";
import { useAuth } from "contexts/AuthContext";
import { SidebarContext } from "contexts/SidebarContext";
import { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes";
import Mentor from "views/admin/default/mentor";
import SignInCentered from "views/auth/signIn";

// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
  const { user, loading } = useAuth();
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const { onOpen } = useDisclosure();
  const { logout } = useAuth();

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  
  const getActiveRoute = (routes: RoutesType[]): string => {
    let activeRoute = "Ed2Dream CRM";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    const routesMap = routes.map((route: RoutesType, key: any) => {
      if (route.layout === "/admin") {
        return (
          <ProtectedRoute
            path={route.layout + route.path}
            component={route.component}
            key={key}
          />
        );
      } else {
        return (
          <Route path={"/auth/sign-in"} component={SignInCentered} key={key} />
        );
      }
    });
    routesMap.push(
      <ProtectedRoute
        path="/admin/mentor/:mentorId"
        component={Mentor}
        key={routes.length + 1}
      />
    );
    return routesMap;
  };
  document.documentElement.dir = "ltr";

  if (!user) {
    logout();
  }

  if (loading) {
    return (
      <Stack marginTop={2}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Box padding="6" boxShadow="lg" bg="white">
          <SkeletonCircle size="10" />
          <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
        </Box>
      </Stack>
    );
  }

  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar routes={routes as RoutesType[]} display="none" {...rest} />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: "100%", xl: "calc( 100% - 290px )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={"Ed2Dream Logo"}
                brandText={getActiveRoute(routes as RoutesType[])}
                secondary={getActiveNavbar(routes as RoutesType[])}
                message={getActiveNavbarText(routes as RoutesType[])}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box
              mx="auto"
              p={{ base: "20px", md: "30px" }}
              pe="20px"
              minH="100vh"
              pt="50px"
            >
              <Switch>
                {getRoutes(routes as RoutesType[])}
                <Redirect from="/" to="/admin/default" />
              </Switch>
            </Box>
          ) : null}
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
