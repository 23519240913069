"use client";

import { useState } from "react";

import { Badge } from "@chakra-ui/react";
import { toLocalizedDateString } from "components/LocalDate";
import { Button } from "components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { ScrollArea } from "components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { motion } from "framer-motion";
import { usePastCalls } from "hooks/usePastCalls";
import { useProfile } from "hooks/useProfile";
import { useUpcomingCalls } from "hooks/useUpcomingCalls";
import {
  BookOpenIcon,
  CalendarIcon,
  CheckCircleIcon,
  GraduationCapIcon,
  PhoneIcon,
  UserIcon,
  XCircleIcon,
} from "lucide-react";

interface RowObjStudents {
  accountId?: string;
  mentor?: string;
  totalUpcomingCalls?: number;
  totalDoneCalls?: number;
  lastActivityAt?: string;
  lastCallAt?: string;
  status?: string;
}

interface DetailsModalProps {
  isOpen: boolean;
  onOpen: () => void; // Add this line
  onClose: () => void;
  selectedStudent: RowObjStudents | null;
}

export default function DetailsModal({
  isOpen,
  onOpen, // Add this line
  onClose,
  selectedStudent,
}: DetailsModalProps) {
  const { calls: upcomingCalls } = useUpcomingCalls(selectedStudent?.accountId);
  const { calls: pastCalls } = usePastCalls(selectedStudent?.accountId);
  const { profile, loading } = useProfile(selectedStudent?.accountId);
  const [activeTab, setActiveTab] = useState("upcoming");

  if (loading) return <div>Loading...</div>;

  const renderCallItem = (call: any, isPast: boolean) => (
    <motion.div
      key={call.id}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className="bg-muted shadow-lg text-card-foreground rounded-lg p-4 hover:shadow-md transition-shadow duration-200"
    >
      <div className="flex justify-between items-center mb-2">
        <h4 className="text-lg font-semibold">{call.details.title}</h4>
        {isPast &&
          (call.done ? (
            <CheckCircleIcon
              className="w-5 h-5 text-green-500"
              aria-label="Completed"
            />
          ) : (
            <XCircleIcon
              className="w-5 h-5 text-red-500"
              aria-label="Not completed"
            />
          ))}
      </div>
      <p className="text-sm text-muted-foreground mb-2">
        <CalendarIcon className="w-4 h-4 inline-block mr-1" />
        {toLocalizedDateString(call.dateTime)}
      </p>
      <p className="text-sm mb-1">
        <span className="font-medium">Topics:</span>{" "}
        {call.details.topics.join(", ")}
      </p>
      <p className="text-sm mb-1">
        <span className="font-medium">Attendees:</span>{" "}
        {call.attendees.map((a: any) => a.profile.displayName).join(", ")}
      </p>
      {isPast && call.note && (
        <p className="text-sm mt-2">
          <span className="font-medium">Notes:</span> {call.note}
        </p>
      )}
    </motion.div>
  );

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-[800px] w-full bg-muted">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold">
            Student Details
          </DialogTitle>
        </DialogHeader>
        <ScrollArea className="h-[65vh] pr-4">
          {selectedStudent && (
            <div className="space-y-2">
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center">
                    <UserIcon className="w-5 h-5 mr-2" />
                    Personal Information
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <span className="font-medium">Name:</span>{" "}
                      {profile?.displayName}
                    </div>
                    <div>
                      <span className="font-medium">Ethnicity:</span>{" "}
                      {profile?.ethnicity}
                    </div>
                    <div>
                      <span className="font-medium">Grade Level:</span>{" "}
                      {profile?.gradeLevel}
                    </div>
                    <div>
                      <span className="font-medium">Intended Major:</span>{" "}
                      {profile?.intendedMajor}
                    </div>
                    <div>
                      <PhoneIcon className="w-4 h-4 inline-block mr-1" />
                      <span className="font-medium">Phone Number:</span>{" "}
                      {profile?.phoneNumber}
                    </div>
                    <div>
                      <span className="font-medium">Mentor:</span>{" "}
                      {selectedStudent.mentor}
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center">
                    <GraduationCapIcon className="w-5 h-5 mr-2" />
                    Academic Information
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <span className="font-medium">Upcoming Calls:</span>{" "}
                      {selectedStudent.totalUpcomingCalls}
                    </div>
                    <div>
                      <span className="font-medium">Completed Calls:</span>{" "}
                      {selectedStudent.totalDoneCalls}
                    </div>
                    <div>
                      <span className="font-medium">Last Activity:</span>{" "}
                      {toLocalizedDateString(selectedStudent.lastActivityAt)}
                    </div>
                    <div>
                      <span className="font-medium">Last Call:</span>{" "}
                      {toLocalizedDateString(selectedStudent.lastCallAt)}
                    </div>
                    <div className="col-span-2">
                      <span className="font-medium">Status:</span>{" "}
                      <Badge>{selectedStudent.status}</Badge>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center">
                    <BookOpenIcon className="w-5 h-5 mr-2" />
                    Calls
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <Tabs value={activeTab} onValueChange={setActiveTab}>
                    <TabsList className="grid w-full grid-cols-2">
                      <TabsTrigger value="upcoming">Upcoming Calls</TabsTrigger>
                      <TabsTrigger value="past">Past Calls</TabsTrigger>
                    </TabsList>
                    <TabsContent value="upcoming" className="mt-4">
                      {upcomingCalls.length > 0 ? (
                        <div className="space-y-4">
                          {upcomingCalls.map((call) =>
                            renderCallItem(call, false)
                          )}
                        </div>
                      ) : (
                        <motion.p
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          No upcoming calls
                        </motion.p>
                      )}
                    </TabsContent>
                    <TabsContent value="past" className="mt-4">
                      {pastCalls.length > 0 ? (
                        <div className="space-y-4">
                          {pastCalls.map((call) => renderCallItem(call, true))}
                        </div>
                      ) : (
                        <motion.p
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          No past calls
                        </motion.p>
                      )}
                    </TabsContent>
                  </Tabs>
                </CardContent>
              </Card>
            </div>
          )}
        </ScrollArea>
        <DialogFooter>
          <Button onClick={onClose}>Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
