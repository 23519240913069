import { QUERY_GET_MENTORS } from "graphql/mentorship.graphql";
import { AccountDto } from "types/graphql-types";
import { useTenantQuery } from "./useTenantQuery";

interface IMentorsData {
  data: { getMentors: AccountDto[] };
  loading: boolean;
  refetch: () => void;
}

export const useMentorsData = (): IMentorsData => {
  return useTenantQuery(QUERY_GET_MENTORS);
};