import { ApolloCache, DefaultContext, FetchResult, MutationFunctionOptions } from "@apollo/client";
import { CREATE_CODE_MUTATION } from "graphql/code.graphql";
import { Code, CreateCodeInput } from "types/graphql-types";
import { useTenantMutation } from "./useTenantQuery";

type IAddCode = {
  addCode: (options?: MutationFunctionOptions<{ createCode: Code; }, { createCodeInput: CreateCodeInput; }, DefaultContext, ApolloCache<any>>) => Promise<FetchResult<{ createCode: Code; }>>;
  loading: boolean;
}

export const useAddCode = (): IAddCode => {
  const [addCode, { loading }] = useTenantMutation<{ createCode: Code }, { createCodeInput: CreateCodeInput }>(CREATE_CODE_MUTATION);

  return { addCode, loading };
};