// Chakra imports
import { Flex, Text } from '@chakra-ui/react';

// Custom components
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
	//   Chakra color mode
	return (
		<Flex alignItems='center' flexDirection='column'>
			<Flex direction='row' mb='12px' align='center' justify='left'>
				<Text
					fontSize={{ base: 'lg', xl: '32px' }}
					color='black'
					fontWeight='bold'
					lineHeight='150%'
					textAlign='center'
					mb='14px'>
					Ed2Dream
				</Text>
				<Text
					fontSize={{ base: 'sm', xl: '18px' }}
					color='black'
					textAlign='left'
					mb='14px'>
					CRM
				</Text>
			</Flex>
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
