import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { jwtDecode } from "jwt-decode";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

export default client;

export interface TokenPayload {
  sub: string;
  username: string;
  type: string;
  iat: number;
  exp: number;
  email: string;
}

export const getTokenPayload = (): TokenPayload | null => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      return jwtDecode<TokenPayload>(token);
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }
  return null;
};
