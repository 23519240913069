import { useQuery } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";
import { QUERY_GET_GET_ACCOUNT } from "graphql/mentor.graphql";
import {
  Box,
  VStack,
  HStack,
  Text,
  Avatar,
  Heading,
  Badge,
  SimpleGrid,
  Container,
  useColorModeValue,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  WrapItem,
  Button,
  IconButton,
  useDisclosure,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react'
import Card from "components/card/Card";
import { useHistory, useParams } from 'react-router-dom';
import { useActivityIndicator } from "hooks/useActivityIndicator";
import Map from "components/Map";
import { useCodesByMentorAccountId } from "hooks/useCodesByMentorAccountId";
import { useCopyToClipboard } from 'usehooks-ts'
import { AddIcon } from "@chakra-ui/icons";
import { useAddCode } from "hooks/useAddCode";
import { useDeleteUser } from "hooks/useDeleteUser";
import { useRef } from "react";

function Mentor() {
  const { setIsLoading } = useActivityIndicator();
  const { mentorId } = useParams<{ mentorId: string }>();
  const { user } = useAuth();
  const [copiedText, copy] = useCopyToClipboard()
  const { data, loading, error } = useQuery(QUERY_GET_GET_ACCOUNT, {
    variables: {
      getAccountId: mentorId,
    },
    skip: !mentorId,
    context: {
      headers: {
        "x-tenant-id": user?.tenantId,
      },
    },
  }); 
  const { data: codesData, loading: codesLoading, refetch: refetchCodes, error: codesError } = useCodesByMentorAccountId(mentorId);
  const bgColor = useColorModeValue('gray.50', 'gray.700')
  const textColor = useColorModeValue('gray.800', 'gray.100')
  const {addCode} = useAddCode();
  const { isOpen, onClose, onOpen } = useDisclosure()
  const cancelRef = useRef()
  const [deleteUser] = useDeleteUser();
  const history = useHistory();

  const handleDelete = async () => {
    await deleteUser({ variables: { deleteUserId: mentorId } });
    await onClose();
    history.push('/admin/mentorship');
  }

  const handleCopy = (text: string) => () => {
    copy(text)
      .then(() => {
        console.log('Copied!', { text })
      })
      .catch(error => {
        console.error('Failed to copy!', error)
      })
  }

  const handleAddCode = async () => {
    await addCode({ variables: { createCodeInput: {
      forceConnectionWith: mentorId,
      used: false,
      tenantId: user?.tenantId,
    } } });
    refetchCodes();
  }
  
  setIsLoading(loading);
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  const accountData = data?.getAccount;

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card py="15px">
        <Flex
          direction={"column"}
        >
          <Container maxW="container.xl" py={8}>
            <VStack spacing={8} align="stretch">
              <Box bg={bgColor} p={6} borderRadius="lg" boxShadow="md">
                <HStack spacing={6}>
                  <Avatar size="2xl" name={accountData?.profile?.displayName} src={accountData?.profile?.photoURL} />
                  <VStack align="start" spacing={2}>
                    <Heading size="xl">{accountData?.profile?.displayName}</Heading>
                    <Badge colorScheme="blue">{accountData?.userType}</Badge>
                    <Text color={textColor}>Grade Level: {accountData?.profile?.gradeLevel}</Text>
                    <Text color={textColor}>Phone: {accountData?.profile?.phoneNumber}</Text>
                  </VStack>
                </HStack>
              </Box>

              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                <Box bg={bgColor} p={6} borderRadius="lg" boxShadow="md">
                  <Heading size="md" mb={4}>Academic Information</Heading>
                  <VStack align="start" spacing={2}>
                    <Text color={textColor}>Major: {accountData?.profile?.major}</Text>
                    <Text color={textColor}>Ethnicity: {accountData?.profile?.ethnicity}</Text>
                    <Text color={textColor}>High School: {accountData?.profile?.highSchool || 'Not specified'}</Text>
                    <Text color={textColor}>College: {accountData?.profile?.college || 'Not specified'}</Text>
                  </VStack>
                </Box>

                <Box bg={bgColor} p={6} borderRadius="lg" boxShadow="md">
                  <Heading size="md" mb={4}>Account Information</Heading>
                  <VStack align="start" spacing={2}>
                    <Text color={textColor}>User ID: {accountData.userId}</Text>
                    <Text color={textColor}>Tenant ID: {accountData.tenantId}</Text>
                    <Text color={textColor}>Created At: {new Date(accountData.createdAt).toLocaleDateString()}</Text>
                  </VStack>
                </Box>
              </SimpleGrid>

              <Box bg={bgColor} p={6} borderRadius="lg" boxShadow="md">
                <Heading size="md" mb={4}>Onboarding Status</Heading>
                <SimpleGrid columns={{ base: 2, md: 3, sm: 2, lg: 4 }} spacing={4}>
                  {Object.entries(accountData.onboardingStatus).map(([key, value]) => (
                    <HStack key={key}>
                      <Text color={textColor}>{key}:</Text>
                      <Badge colorScheme={value ? 'green' : 'red'}>{value ? 'Completed' : 'Pending'}</Badge>
                    </HStack>
                  ))}
                </SimpleGrid>
              </Box>

              <Box bg={bgColor} p={6} borderRadius="lg" boxShadow="md">
                <Heading size="md" mb={4}>Location</Heading>
                {accountData?.profile?.location.latitude && accountData?.profile?.location.longitude ? (
                  <>
                    <Text color={textColor} mb={2}>
                      Latitude: {accountData.profile.location.latitude}, 
                      Longitude: {accountData.profile.location.longitude}
                    </Text>
                    <Box height="300px" width="100%">
                      <Map 
                        latitude={accountData.profile.location.latitude} 
                        longitude={accountData.profile.location.longitude} 
                      />
                    </Box>
                  </>
                ) : (
                  <Text color={textColor}>Location not available</Text>
                )}
              </Box>
              <Box bg={bgColor} p={6} borderRadius="lg" boxShadow="md">
                <HStack>
                  <Heading size="md" mb={4}>Onboarding Codes  </Heading>
                  <IconButton
                    variant='outline'
                    colorScheme='teal'
                    aria-label='Add Code'
                    size="sm"
                    icon={<AddIcon />}
                    onClick={handleAddCode}
                  />
                </HStack>
                {codesLoading ? (
                  <Text>Loading codes...</Text>
                ) : codesError ? (
                  <Text color="red.500">Error loading codes: {codesError.message}</Text>
                ) : (
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Code</Th>
                        <Th>Email</Th>
                        <Th>Used</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {codesData?.getCodesByMentorAccountId?.map((code, index) => {
                        return (
                          <Tr key={index}>
                            <Td>
                              <WrapItem>
                                <Button 
                                  colorScheme={copiedText === code.code ? 'green' : 'gray'} 
                                  onClick={handleCopy(code.code)}
                                >
                                  {copiedText === code.code ? 'Copied!' : code.code}
                                </Button>
                              </WrapItem>
                            </Td>
                            <Td>{code.email || 'N/A'}</Td>
                            <Td>
                              <Badge colorScheme={code.used ? 'red' : 'green'}>
                                {code.used ? 'Used' : 'Unused'}
                              </Badge>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                )}
              </Box>
              <Button colorScheme='red' onClick={onOpen}>
                Delete Mentor
              </Button>
            </VStack>
          </Container>
        </Flex>
      </Card>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Mentor
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={() => handleDelete()} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
    </Box>
  )
}

export default Mentor;