import { gql } from "@apollo/client";

export const GET_STUDENTS_ANALYTICS_DATA = gql`
  query StudentsAnalytics {
    studentsAnalytics {
      userId
      accountId
      displayName
      progress
      mentor
      mentorId
      totalUpcomingCalls
      totalDoneCalls
      status
      type
      lastCallAt
      lastActivityAt
      lastSyncAt
    }
  }
`;

export const GET_MENTORS_ANALYTICS_DATA = gql`
  query MentorsAnalytics {
    mentorsAnalytics {
      userId
      accountId
      displayName
      photoUrl
      totalMentees
      totalDoneCalls
      totalUpcomingCalls
      meanProgressions
      email
      major
      status
      type
      lastCallAt
      lastActivityAt
      lastSyncAt
    }
  }
`;

export const GET_TENANT_ANALYTICS_DATA = gql`
  query TenantsAnalytics {
    tenantsAnalytics {
      totalStudentsOnboarded
      totalActiveUsersLast14Days
      totalDoneCalls
      totalUpcomingCalls
      nMessages
      totalCallsDuration
      averageCallsDuration
      type
      lastSyncAt
    }
  }
`;

export const UPCOMING_CALLS_QUERY = gql`
  query MyCalls($accountId: String!) {
    myCalls(accountId: $accountId) {
      upcoming {
        id
        dateTime
        title
        topics
        details {
          title
          topics
        }
        highSchool
        done
        duration
        allDay
        defaultTz
        attendees {
          id
          userId
          tenantId
          mentorId
          profile {
            displayName
            college
            photoURL
          }
          sendBirdUser {
            userId
            accessToken
          }
          rating
          createdAt
          updatedAt
        }
        note
      }
    }
  }
`;

export const PAST_CALLS_QUERY = gql`
  query MyCalls($accountId: String!) {
    myCalls(accountId: $accountId) {
      past {
        attendees {
          id
          profile {
            college
            displayName
            photoURL
          }
          mentorId
          sendBirdUser {
            accessToken
            userId
          }
          tenantId
        }
        id
        dateTime
        title
        topics
        details {
          title
          topics
        }
        highSchool
        done
        duration
        allDay
        defaultTz
        note
      }
    }
  }
`;

export const DONE_CALLS_QUERY = gql`
  query MyCalls($accountId: String!) {
    myCalls(accountId: $accountId) {
      done {
        note
        duration
        details {
          title
          topics
        }
        dateTime
        done
        defaultTz
        attendees {
          profile {
            displayName
          }
        }
      }
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query GetAccount($getAccountId: String!) {
    getAccount(id: $getAccountId) {
      profile {
        college
        displayName
        ethnicity
        gradeLevel
        highSchool
        intendedMajor
        interests
        location {
          latitude
          longitude
        }
        phoneNumber
      }
    }
  }
`;
