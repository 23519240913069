import { QueryResult, useQuery } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";
import { GET_USER_PROFILE } from "graphql/data.graphql";
import { useEffect, useState } from "react";

interface ProfileData {
  getAccount: {
    profile: any;
  };
}

interface ProfileResponse {
  loading: boolean;
  error: Error | undefined;
  profile: any;
  refetch: QueryResult<ProfileData, ProfileVars>["refetch"];
}

interface ProfileVars {
  getAccountId: string;
}

export const useProfile = (accountId: string): ProfileResponse => {
  const { user } = useAuth();
  const [profile, setProfile] = useState(null);

  const { loading, error, data, refetch } = useQuery<ProfileData, ProfileVars>(
    GET_USER_PROFILE,
    {
      skip: !accountId,
      variables: { getAccountId: accountId },
      context: {
        headers: {
          "x-tenant-id": user?.tenantId,
        },
      },
    }
  );

  useEffect(() => {
    if (data?.getAccount?.profile) {
      setProfile(data.getAccount.profile);
    } else {
      setProfile(null);
    }
  }, [data, loading, error]);

  return { loading, error, profile, refetch };
};
