import { GET_STUDENTS_ANALYTICS_DATA } from "graphql/data.graphql";
import { StudentsAnalyticsDto } from "types/graphql-types";
import { useTenantQuery } from "./useTenantQuery";

interface IStudentsAnalyticsData {
  data: { studentsAnalytics: StudentsAnalyticsDto[] };
  loading: boolean;
}

export const useStudentsAnalyticsData = (): IStudentsAnalyticsData => {
  return useTenantQuery(GET_STUDENTS_ANALYTICS_DATA);
};