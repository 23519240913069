import { ApolloCache, DefaultContext, FetchResult, MutationFunctionOptions } from "@apollo/client";
import { SCHEDULE_PUSH_NOTIFICATION_MUTATION } from "graphql/push.graphql";
import { Role } from "types/graphql-types";
import { useTenantMutation } from "./useTenantQuery";

type ISchedulePushNotifications = {
  schedulePushNotifications: (options?: MutationFunctionOptions<{ }, { body: string, fcm?: string, title: string, userType: Role.Mentor | Role.Student, tenantId: string, tokens?: string[] }, DefaultContext, ApolloCache<any>>) => Promise<FetchResult<{ }>>;
  loading: boolean;
}

export const useSchedulePushNotifications = (): ISchedulePushNotifications => {
  const [schedulePushNotifications, { loading }] = useTenantMutation<{ }, { body: string, fcm?: string, title: string, userType: Role.Mentor | Role.Student, tenantId: string, tokens?: string[] }>(SCHEDULE_PUSH_NOTIFICATION_MUTATION);

  return { schedulePushNotifications, loading };
};