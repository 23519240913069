import { jwtDecode } from "jwt-decode";
import React, { createContext, useContext, useState } from "react";
import client, { getTokenPayload, TokenPayload } from "../apollo";
import { MUTATION_LOGIN } from "../graphql/auth.graphql";
import { AdminDto } from "types/graphql-types";
import { useHistory } from "react-router-dom";

interface User {
  tokenInfo: TokenPayload;
  tenantId: any;
  profile?: AdminDto;
}

interface AuthContextType {
  user: User | null;
  loading: boolean;
  login: (email: string, password: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  login: (email: string, password: string) => {},
  logout: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const tenantId = localStorage.getItem("tenantId");
  const profile = localStorage.getItem("profile");
  const [user, setUser] = useState<User | null>({ tokenInfo: getTokenPayload(), tenantId, profile: profile ? JSON.parse(profile) : undefined } ?? null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const login = (identifier: string, password: string) => {
    setLoading(true);
    client
      .mutate({
        mutation: MUTATION_LOGIN,
        variables: {
          input: {
            identifier,
            password,
          },
        },
      })
      .then((response) => {
        const token = response.data.adminSigningIn.accessToken;
        const adminResponse = response.data.adminSigningIn.admin;
        const tenantId = adminResponse.tenantId;

        localStorage.setItem("token", token);
        localStorage.setItem("tenantId", tenantId);
        localStorage.setItem("profile", JSON.stringify(adminResponse));

        const decoded = jwtDecode<TokenPayload>(token);

        setUser({ tokenInfo: decoded, tenantId, profile: { ...adminResponse } });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Login failed", error);
        setLoading(false);
      });
  };

  const logout = async () => {
    setUser((prev) => ({ ...prev, tokenInfo: null }));
    await Promise.allSettled([
      localStorage.removeItem("token"),
      localStorage.removeItem("tenantId"),
      localStorage.removeItem("profile"),
    ]);
    if (window.location.pathname !== "/auth/sign-in") {
      if (history) history.push("/auth/sign-in");
      else window.location.href = "/#/auth/sign-in";
    }
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
