import React, { useState } from 'react'
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  Input,
  Textarea,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
} from '@chakra-ui/react'
import ConfigEditor from './components/config-editor';

// This would typically come from a prop or context, but for this example, we'll use a placeholder
const initialData = {
  "_id": {
    "$oid": "672a7ffe0c45af11a339ab0b"
  },
  "id": "fakeuchicago",
  "storageBucket": "fakeuchicago",
  "createdAt": {
    "$date": "2024-05-22T15:18:13.003Z"
  },
  "__v": 0,
  "domain": "fakeuchicago.edu",
  "key": "fakeuchicago",
  "SDUI": {
    "university": {
      "commonApplication": {
        "text": "Common Application",
        "link": "https://www.commonapp.org"
      },
      "coatOfArms": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/University_of_Chicago_Coat_of_arms.png?alt=media&token=5904e867-fa30-4ec0-a838-fe5a44739e39",
      "name": "FAKE University of Chicago",
      "logo": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2Flogo.png?alt=media&token=e6746107-1221-4e00-ac79-6cbece70dec5",
      "description": "A diversity of people and ideas, coupled with free and open discourse, lays the foundation for students and scholars to bring forth original ideas that define fields and enrich human life.",
      "profileImage": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/uchicago.png?alt=media&token=320a867b-14d1-4583-8f9c-2b6489028bce",
      "videos": [
        {
          "id": 20,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/preCallVideos%2F%231%2FE2D%20Call%20%231%20Trailer.mp4?alt=media&token=9ed58827-ae71-4ca9-a2e2-0488ea443099"
        },
        {
          "id": 2,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2FUChicago%2F1.mp4?alt=media&token=672de73f-bb85-4b76-9cb5-47607f2f3bac"
        },
        {
          "id": 3,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2FUChicago%2F2.mp4?alt=media&token=dbcce6ca-ba46-45ce-b901-618eabe731a5"
        },
        {
          "id": 4,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2FUChicago%2F3.mp4?alt=media&token=59ab3218-6d4d-4035-9527-24a0204cb592"
        },
        {
          "id": 5,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2FUChicago%2F4.mp4?alt=media&token=69c60a27-450a-47a5-b973-eba0efb40e7b"
        },
        {
          "id": 6,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2FUChicago%2F5.mp4?alt=media&token=3d02930c-4405-44da-a061-0964cc23bb32"
        },
        {
          "id": 7,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2FUChicago%2F6.mp4?alt=media&token=740a1601-20c2-4509-8dd1-565ebccd1d62"
        },
        {
          "id": 8,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2FUChicago%2F7.mp4?alt=media&token=4a81f343-c6c2-4236-977c-577aacb1dd09"
        },
        {
          "id": 9,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2FUChicago%2F8.mp4?alt=media&token=c88284ba-393c-4284-8f9a-402a2cdb7baf"
        },
        {
          "id": 10,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2FUChicago%2F9.mp4?alt=media&token=2e9bcf35-2b98-45e5-85b9-d8bb37634361"
        },
        {
          "id": 11,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2FUChicago%2F10.mp4?alt=media&token=fc1d13d1-64bf-4085-ad21-8d2be793cd43"
        },
        {
          "id": 12,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2FUChicago%2F11.mp4?alt=media&token=46088185-0012-4d68-8455-f59370a2bc8e"
        },
        {
          "id": 13,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2FUChicago%2F12.mp4?alt=media&token=df465df7-0736-4a5e-9297-6da683aba70a"
        },
        {
          "id": 14,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2FUChicago%2F13.mp4?alt=media&token=fb00b61c-eaf6-4d13-a10e-f21405e1f983"
        },
        {
          "id": 15,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2FUChicago%2F14.mp4?alt=media&token=068d97fb-2ff8-4fe4-82fc-7b235f7ac0b5"
        },
        {
          "id": 16,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2FUChicago%2F15.mp4?alt=media&token=cd1efabe-65c4-4dfd-9cdf-ae2a6afb14fb"
        },
        {
          "id": 17,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2FUChicago%2F16.mp4?alt=media&token=787fd11c-13e4-48d1-892f-5ff8a28e2d3f"
        },
        {
          "id": 18,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2FUChicago%2F17.mp4?alt=media&token=e22107aa-01c9-445e-8fdd-be573a80d9f0"
        },
        {
          "id": 19,
          "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2FUChicago%2F18.mp4?alt=media&token=5e33c021-bd00-4c60-be79-5ded6d530200"
        }
      ],
      "brandingDescription": "\"Chicago is not only in our name, it’s woven into the fabric of this institution. Located in the Hyde Park neighborhood, we benefit from the diversity, arts, and vibrant culture of our South Side community\"",
      "latestNews": [
        {
          "id": "1",
          "linkURL": "https://chicagomaroon.com/27132/news/greek-life-uchicago/",
          "imageUrl": "https://chicagomaroon.com/wp-content/uploads/2019/09/1.jpg",
          "sourceText": "Source: The Chicago Maroon",
          "title": "Sororities and Fraternities at UChicago"
        },
        {
          "id": "2",
          "linkURL": "https://www.timeout.com/chicago/restaurants/cheap-food-in-chicago",
          "imageUrl": "https://media.timeout.com/images/106077367/1372/772/image.webp",
          "sourceText": "Source: Timeout Chicago",
          "title": "The best cheap eats in Chicago"
        },
        {
          "id": "3",
          "linkURL": "https://www.timeout.com/chicago/hyde-park",
          "imageUrl": "https://media.timeout.com/images/102596433/1372/772/image.webp",
          "sourceText": "Source: Timeout Chicago",
          "title": "A complete guide to the Hyde Park neighborhood"
        },
        {
          "id": "4",
          "linkURL": "https://www.timeout.com/chicago/news/hyde-park-is-one-of-time-outs-coolest-neighborhoods-in-the-world-101723",
          "imageUrl": "https://media.timeout.com/images/106054376/1372/772/image.webp",
          "sourceText": "Source: Timeout Chicago",
          "title": "Hyde Park is one of Time Out's coolest neighborhoods in the world"
        },
        {
          "id": "5",
          "linkURL": "https://www.espn.com/mens-college-basketball/story/_/id/39723603/relive-highest-scoring-mens-march-madness-performances",
          "imageUrl": "https://a.espncdn.com/combiner/i?img=%2Fphoto%2F2013%2F0211%2Fncb_a_carr_gb1_800.jpg&w=800&h=450&scale=crop&cquality=80&location=origin&format=jpg",
          "sourceText": "Source: ESPN",
          "title": "Relive highest-scoring men's March Madness performances"
        },
        {
          "id": "6",
          "linkURL": "https://athletics.uchicago.edu/news/2024/3/14/softball-falls-late-in-two-florida-contests.aspx",
          "imageUrl": "https://athletics.uchicago.edu/images/2024/3/14/SB-Carroll-Murphy5.JPG?width=1920&quality=80&format=jpg",
          "sourceText": "Source: Uchicago Athletics",
          "title": "Softball Falls Late In Two Florida Contests"
        },
        {
          "id": "7",
          "linkURL": "https://chicagomaroon.com/28618/arts/uchicago-big-small-screen-films-tv-shows-set-campus/",
          "imageUrl": "https://chicagomaroon.com/wp-content/uploads/2021/09/IMG_5292-900x300.jpg",
          "sourceText": "Source: UChicago New",
          "title": "Films and TV Shows Set on Campus"
        },
        {
          "id": "16",
          "linkURL": "https://mashable.com/article/oppenheimer-university-of-chicago-football-field-cp-1-explainer",
          "imageUrl": "https://helios-i.mashable.com/imagery/articles/03kpqaZ363nxR9EbnvFqPSy/hero-image.fill.size_1248x702.v1689970967.jpg",
          "sourceText": "Source: Mashable",
          "title": "'Oppenheimer': Yes, there really was a nuclear reactor under a football field."
        },
        {
          "id": "8",
          "linkURL": "https://news.uchicago.edu/story/five-uchicago-scholars-awarded-prestigious-sloan-fellowships-2024",
          "imageUrl": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/news%2Fnews4.jpeg?alt=media&token=f06a8deb-377f-4033-bb2f-c5f6a8035795",
          "sourceText": "Source: UChicago News",
          "title": "Five UChicago scholars awarded prestigious Sloan Fellowships in 2024"
        },
        {
          "id": "9",
          "linkURL": "https://news.uchicago.edu/story/engineer-designs-molecules-our-quantum-future",
          "imageUrl": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/news%2Fnoticia2.jpg?alt=media&token=42b9a0af-4a2e-4c74-8ee0-f24c39b3022e",
          "sourceText": "Source: UChicago News",
          "title": "Engineer designs molecules for our quantum future"
        },
        {
          "id": "10",
          "linkURL": "https://news.uchicago.edu/story/former-nfl-player-spencer-paysinger-deliver-2024-kent-lecture-uchicago",
          "imageUrl": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/news%2Fnews8.png?alt=media&token=d2ea19cf-7cc2-4093-87a9-b7c5d2ec54d1",
          "sourceText": "Source: UChicago News",
          "title": "Former NFL player Spencer Paysinger to deliver 2024 Kent Lecture at UChicago"
        },
        {
          "id": "11",
          "linkURL": "https://news.uchicago.edu/story/learning-habits-mind",
          "imageUrl": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/news%2Fnews3.jpeg?alt=media&token=30a0028e-8502-4de1-a7d5-50595d9ee43c",
          "sourceText": "Source: UChicago News",
          "title": "Learning the habits of the “Mind”"
        },
        {
          "id": "12",
          "linkURL": "https://news.uchicago.edu/the-day-tomorrow-began",
          "imageUrl": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/news%2FTDTB_Website%20Header-01.png?alt=media&token=63d46de1-2f54-4de3-984a-f5f4fb13b64e",
          "sourceText": "Source: UChicago News",
          "title": "The day tomorrow began"
        },
        {
          "id": "13",
          "linkURL": "https://news.uchicago.edu/story/learning-about-robots-and-college-readiness",
          "imageUrl": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/news%2Fnews5.jpeg?alt=media&token=ae9d6e77-91ba-497f-9083-c79b9c51f689",
          "sourceText": "Source: UChicago News",
          "title": "Learning about robots and college readiness"
        },
        {
          "id": "14",
          "linkURL": "https://news.uchicago.edu/story/uchicago-symposium-connects-diverse-firms-and-decision-makers",
          "imageUrl": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/news%2Fnews6.jpeg?alt=media&token=3daf106f-229b-4d89-b33e-3f7cf6ca7150",
          "sourceText": "Source: UChicago News",
          "title": "UChicago symposium connects diverse firms with decision-makersnew"
        },
        {
          "id": "15",
          "linkURL": "https://news.uchicago.edu/story/six-members-uchicago-community-receive-2024-diversity-leadership-awards",
          "imageUrl": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/news%2Fnews7.jpeg?alt=media&token=5b75460e-446e-4ce9-bd55-4d25e9111397",
          "sourceText": "Source: UChicago News",
          "title": "Six members of UChicago community to receive 2024 Diversity Leadership Awards"
        }
      ],
      "events": [
        {
          "id": "1",
          "imageUrl": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/news%2Fdinossaurooooo.webp?alt=media&token=05515ca6-e17b-442b-9592-2ce4cf2f6eba",
          "title": "Bibliosaurus! Dinosaurs In The Popular Imagination",
          "dateTime": "19 Apr, 2024 - 9:00am - 4:45pm",
          "location": "Regenstein Library, The Special Collections Research Center",
          "linkURL": "https://events.uchicago.edu/event/227283-bibliosaurus-dinosaurs-in-the-popular-imagination"
        },
        {
          "id": "2",
          "imageUrl": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/university%2Fevents2.png?alt=media&token=44e6c40c-9226-4bff-a45e-f90fd924dbb3",
          "title": "Neuroscience Student Talks: Claire McKinnon And Michelle Miller",
          "dateTime": "06 May, 2024 - 12:00pm - 1:00pm",
          "location": "Surgery Brain Research Institutes, J461",
          "linkURL": "https://events.uchicago.edu/event/223753-neuroscience-student-talks-claire-mckinnon-and"
        },
        {
          "id": "3",
          "imageUrl": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/news%2Fevenet3.webp?alt=media&token=af03b7a9-5ac3-4c46-a5f4-6cce5704d011",
          "title": "Family Day: Birds Of A Feather",
          "dateTime": "11 May, 2024 - 1:00pm - 4:00pm",
          "location": "Smart Museum of Art",
          "linkURL": "https://events.uchicago.edu/event/231371-family-day-birds-of-a-feather"
        },
        {
          "id": "4",
          "imageUrl": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/news%2Fevent4.webp?alt=media&token=1c019fff-1612-47b8-8d83-56c8bfe34f14",
          "title": "Summer Carillon Tower Tour",
          "dateTime": "03 Jul, 2024 - 4:30pm - 5:15pm",
          "location": "Rockefeller Memorial Chapel",
          "linkURL": "https://events.uchicago.edu/event/228001-summer-carillon-tower-tour"
        }
      ]
    }
  },
  "name": "",
  "configs": {
    "maxStudentsPerMentor": 10,
    "sendbirdAppId": "52CE65AA-82CE-4325-9AC6-52C2D5387BA9",
    "appVersion": "1.92.1",
    "callsTitlesTopics": [
      {
        "call": "Building Trust",
        "id": 1,
        "front": {
          "title": "Call 1",
          "subtitle": "Ready to Shape Your Future? 🚀✨",
          "topics": [
            {
              "id": "1",
              "title": "🎯 Crafting Your Personal Game Plan"
            },
            {
              "id": "2",
              "title": "🏫 Building Your College List"
            },
            {
              "id": "3",
              "title": "🌐 Dive Into the Common App"
            },
            {
              "id": "4",
              "title": "📝 Perfecting Personal Statements & Essays"
            },
            {
              "id": "5",
              "title": "📚 Preparing for Standardized Tests"
            },
            {
              "id": "6",
              "title": "💸 Securing Financial Aid & Scholarships"
            },
            {
              "id": "7",
              "title": "🚗 Taking Charge of Your Future - You’re in the driver’s seat!"
            }
          ]
        },
        "videos": [
          {
            "id": 1,
            "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/preCallVideos%2F%231%2FE2D%20Call%20%231%20Trailer.mp4?alt=media&token=9ed58827-ae71-4ca9-a2e2-0488ea443099"
          }
        ]
      },
      {
        "call": "Social Life",
        "id": 2,
        "front": {
          "title": "Call 2",
          "subtitle": "Ready to Shape Your Future? 🚀✨",
          "topics": [
            {
              "id": "1",
              "title": "🎯 Crafting Your Personal Game Plan"
            },
            {
              "id": "2",
              "title": "🏫 Building Your College List"
            },
            {
              "id": "3",
              "title": "🌐 Dive Into the Common App"
            },
            {
              "id": "4",
              "title": "📝 Perfecting Personal Statements & Essays"
            },
            {
              "id": "5",
              "title": "📚 Preparing for Standardized Tests"
            },
            {
              "id": "6",
              "title": "💸 Securing Financial Aid & Scholarships"
            },
            {
              "id": "7",
              "title": "🚗 Taking Charge of Your Future - You’re in the driver’s seat!"
            }
          ]
        },
        "videos": [
          {
            "id": 1,
            "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/preCallVideos%2F%232%2Fprecall2.mp4?alt=media&token=8bf3d908-fb3c-4906-8e0a-b576c89d6dcb"
          }
        ]
      },
      {
        "call": "Academics",
        "id": 3,
        "front": {
          "title": "Call 3",
          "subtitle": "Ready to Shape Your Future? 🚀✨",
          "topics": [
            {
              "id": "1",
              "title": "🎯 Crafting Your Personal Game Plan"
            },
            {
              "id": "2",
              "title": "🏫 Building Your College List"
            },
            {
              "id": "3",
              "title": "🌐 Dive Into the Common App"
            },
            {
              "id": "4",
              "title": "📝 Perfecting Personal Statements & Essays"
            },
            {
              "id": "5",
              "title": "📚 Preparing for Standardized Tests"
            },
            {
              "id": "6",
              "title": "💸 Securing Financial Aid & Scholarships"
            },
            {
              "id": "7",
              "title": "🚗 Taking Charge of Your Future - You’re in the driver’s seat!"
            }
          ]
        },
        "videos": [
          {
            "id": 1,
            "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/preCallVideos%2F%233%2FCALL%20%233%20(1).mp4?alt=media&token=b2640825-4eb1-48ed-aa67-9d503f43026b"
          }
        ]
      },
      {
        "call": "Hyde Park & Life on Campus",
        "id": 4,
        "front": {
          "title": "Call 4",
          "subtitle": "Ready to Shape Your Future? 🚀✨",
          "topics": [
            {
              "id": "1",
              "title": "🎯 Crafting Your Personal Game Plan"
            },
            {
              "id": "2",
              "title": "🏫 Building Your College List"
            },
            {
              "id": "3",
              "title": "🌐 Dive Into the Common App"
            },
            {
              "id": "4",
              "title": "📝 Perfecting Personal Statements & Essays"
            },
            {
              "id": "5",
              "title": "📚 Preparing for Standardized Tests"
            },
            {
              "id": "6",
              "title": "💸 Securing Financial Aid & Scholarships"
            },
            {
              "id": "7",
              "title": "🚗 Taking Charge of Your Future - You’re in the driver’s seat!"
            }
          ]
        },
        "videos": [
          {
            "id": 1,
            "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/preCallVideos%2F%234%2FCall%20%234.mp4?alt=media&token=9b2b3445-238d-4bb3-97cd-b5732abf595d"
          }
        ]
      },
      {
        "call": "City Life (Chicago)",
        "id": 5,
        "front": {
          "title": "Call 5",
          "subtitle": "Ready to Shape Your Future? 🚀✨",
          "topics": [
            {
              "id": "1",
              "title": "🎯 Crafting Your Personal Game Plan"
            },
            {
              "id": "2",
              "title": "🏫 Building Your College List"
            },
            {
              "id": "3",
              "title": "🌐 Dive Into the Common App"
            },
            {
              "id": "4",
              "title": "📝 Perfecting Personal Statements & Essays"
            },
            {
              "id": "5",
              "title": "📚 Preparing for Standardized Tests"
            },
            {
              "id": "6",
              "title": "💸 Securing Financial Aid & Scholarships"
            },
            {
              "id": "7",
              "title": "🚗 Taking Charge of Your Future - You’re in the driver’s seat!"
            }
          ]
        },
        "videos": [
          {
            "id": 1,
            "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/preCallVideos%2F%235%2FCall%20%235%20TIKTOK.mp4?alt=media&token=9264d03f-b05e-425e-86a8-929001cb9fe6"
          }
        ]
      },
      {
        "call": "Painting the Picture",
        "id": 6,
        "front": {
          "title": "Call 6",
          "subtitle": "Ready to Shape Your Future? 🚀✨",
          "topics": [
            {
              "id": "1",
              "title": "🎯 Crafting Your Personal Game Plan"
            },
            {
              "id": "2",
              "title": "🏫 Building Your College List"
            },
            {
              "id": "3",
              "title": "🌐 Dive Into the Common App"
            },
            {
              "id": "4",
              "title": "📝 Perfecting Personal Statements & Essays"
            },
            {
              "id": "5",
              "title": "📚 Preparing for Standardized Tests"
            },
            {
              "id": "6",
              "title": "💸 Securing Financial Aid & Scholarships"
            },
            {
              "id": "7",
              "title": "🚗 Taking Charge of Your Future - You’re in the driver’s seat!"
            }
          ]
        },
        "videos": [
          {
            "id": 1,
            "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/preCallVideos%2F%236%2FCall%20%236.mp4?alt=media&token=2fa85f43-013f-4eab-afef-62ee274664fc"
          }
        ]
      },
      {
        "call": "Expanding Horizons",
        "id": 7,
        "front": {
          "title": "Call 7",
          "subtitle": "Ready to Shape Your Future? 🚀✨",
          "topics": [
            {
              "id": "1",
              "title": "🎯 Crafting Your Personal Game Plan"
            },
            {
              "id": "2",
              "title": "🏫 Building Your College List"
            },
            {
              "id": "3",
              "title": "🌐 Dive Into the Common App"
            },
            {
              "id": "4",
              "title": "📝 Perfecting Personal Statements & Essays"
            },
            {
              "id": "5",
              "title": "📚 Preparing for Standardized Tests"
            },
            {
              "id": "6",
              "title": "💸 Securing Financial Aid & Scholarships"
            },
            {
              "id": "7",
              "title": "🚗 Taking Charge of Your Future - You’re in the driver’s seat!"
            }
          ]
        },
        "videos": [
          {
            "id": 1,
            "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/preCallVideos%2F%237%2Funlock-your-dreams-TIKTOK.mp4?alt=media&token=c2a9cd61-84c8-49c1-8168-1f8f92533dee"
          }
        ]
      },
      {
        "call": "The UChicago Premium / Internships / Pre-Professional",
        "id": 8,
        "front": {
          "title": "Call 8",
          "subtitle": "Ready to Shape Your Future? 🚀✨",
          "topics": [
            {
              "id": "1",
              "title": "🎯 Crafting Your Personal Game Plan"
            },
            {
              "id": "2",
              "title": "🏫 Building Your College List"
            },
            {
              "id": "3",
              "title": "🌐 Dive Into the Common App"
            },
            {
              "id": "4",
              "title": "📝 Perfecting Personal Statements & Essays"
            },
            {
              "id": "5",
              "title": "📚 Preparing for Standardized Tests"
            },
            {
              "id": "6",
              "title": "💸 Securing Financial Aid & Scholarships"
            },
            {
              "id": "7",
              "title": "🚗 Taking Charge of Your Future - You’re in the driver’s seat!"
            }
          ]
        },
        "videos": [
          {
            "id": 1,
            "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/preCallVideos%2F%237%2Funlock-your-dreams-TIKTOK.mp4?alt=media&token=c2a9cd61-84c8-49c1-8168-1f8f92533dee"
          }
        ]
      },
      {
        "call": "Financial Aid Call",
        "id": 9,
        "front": {
          "title": "Call 9",
          "subtitle": "Ready to Shape Your Future? 🚀✨",
          "topics": [
            {
              "id": "1",
              "title": "🎯 Crafting Your Personal Game Plan"
            },
            {
              "id": "2",
              "title": "🏫 Building Your College List"
            },
            {
              "id": "3",
              "title": "🌐 Dive Into the Common App"
            },
            {
              "id": "4",
              "title": "📝 Perfecting Personal Statements & Essays"
            },
            {
              "id": "5",
              "title": "📚 Preparing for Standardized Tests"
            },
            {
              "id": "6",
              "title": "💸 Securing Financial Aid & Scholarships"
            },
            {
              "id": "7",
              "title": "🚗 Taking Charge of Your Future - You’re in the driver’s seat!"
            }
          ]
        },
        "videos": [
          {
            "id": 1,
            "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/preCallVideos%2F%237%2Funlock-your-dreams-TIKTOK.mp4?alt=media&token=c2a9cd61-84c8-49c1-8168-1f8f92533dee"
          }
        ]
      },
      {
        "call": "Common App",
        "id": 10,
        "front": {
          "title": "Call 10",
          "subtitle": "Ready to Shape Your Future? 🚀✨",
          "topics": [
            {
              "id": "1",
              "title": "🎯 Crafting Your Personal Game Plan"
            },
            {
              "id": "2",
              "title": "🏫 Building Your College List"
            },
            {
              "id": "3",
              "title": "🌐 Dive Into the Common App"
            },
            {
              "id": "4",
              "title": "📝 Perfecting Personal Statements & Essays"
            },
            {
              "id": "5",
              "title": "📚 Preparing for Standardized Tests"
            },
            {
              "id": "6",
              "title": "💸 Securing Financial Aid & Scholarships"
            },
            {
              "id": "7",
              "title": "🚗 Taking Charge of Your Future - You’re in the driver’s seat!"
            }
          ]
        },
        "videos": [
          {
            "id": 1,
            "url": "https://firebasestorage.googleapis.com/v0/b/ed2dream.appspot.com/o/preCallVideos%2F%237%2Funlock-your-dreams-TIKTOK.mp4?alt=media&token=c2a9cd61-84c8-49c1-8168-1f8f92533dee"
          }
        ]
      }
    ],
    "googleMapsApiKey": "AIzaSyAhpeaKL4QwYz3K5V8GL4cVhL0QOy5eyJM",
    "googleWebClientId": "851302758732-m0igcrkkmrnkq56gqu884bf0j1g0f8rk.apps.googleusercontent.com",
    "sendbirdApiToken": "474e5c3d4c910cbf616afe95304164e4f1d32fe1",
    "testEmails": [
      "jdmaydan@gmail.com",
      "jonathan@ed2dream.com",
      "jmaydan@umich.edu",
      "alvaropaconeto@gmail.com",
      "alvaro@ed2dream.com",
      "felipe@ed2dream.com",
      "felipexy1@gmail.com",
      "felipexy@hotmail.com"
    ],
    "GAC": "G-43LJJZ6LCY",
    "prizePlacement": [
      {
        "predefinedCallId": 1,
        "prizeId": 0
      },
      {
        "predefinedCallId": 3,
        "prizeId": 1
      },
      {
        "predefinedCallId": 9,
        "prizeId": 1
      }
    ],
    "prizes": [
      {
        "id": 0,
        "title": "A really nice cap!",
        "img": ""
      },
      {
        "id": 1,
        "title": "The coolest T-shirt ever!",
        "img": ""
      }
    ],
    "majorsList": [
      "Anthropology",
      "Art History",
      "Astronomy and Astrophysics",
      "Biological Chemistry",
      "Biological Sciences",
      "Chemistry",
      "Cinema and Media Studies",
      "Classical Studies",
      "Comparative Human Development",
      "Comparative Literature",
      "Computational and Applied Mathematics",
      "Computer Science",
      "Creative Writing",
      "Data Science",
      "East Asian Languages and Civilizations",
      "Economics",
      "English Language and Literature",
      "Enviroment, Geography, and Urbanization",
      "Enviromental Science",
      "Fundamentals: Issues and Texts",
      "Gender and Sexuality Studies",
      "Geographic Information Science",
      "Geophysical Sciences",
      "Germanic Studies",
      "Global Studies",
      "History",
      "History, Philosophy, and Social Studies of Science and Medicine",
      "Human Rights",
      "Inquiry and Research in the Humanities",
      "Jewish Studies",
      "Latin American and Caribbean Studies",
      "Law, Letters, and Society",
      "Linguistics",
      "Mathematics",
      "Media Arts and Design",
      "Medieval Studies",
      "Molecular Engineering",
      "Music",
      "Near Eastern Languages and Civilizations",
      "Neuroscience",
      "Philosophy",
      "Physics",
      "Political Science",
      "Psychology",
      "Public Policy Studies",
      "Race, Diaspora, and Indigeneity",
      "Religious Studies",
      "Romance Languages and Literatures",
      "Russian and East European Studies",
      "Sociology",
      "South Asian Languages and Civilizations",
      "Statistics",
      "Theater and Performance Studies",
      "Visual Arts"
    ],
    "quiz": [
      {
        "type": "multipleChoice",
        "category": "personal",
        "question": "Did either of your parents graduate from a 4-year college or university?",
        "options": [
          {
            "title": "Yes",
            "image": ""
          },
          {
            "title": "No",
            "image": ""
          }
        ],
        "weight": 1
      },
      {
        "type": "multipleChoice",
        "category": "personal",
        "question": "What do you value most during your time in college?",
        "options": [
          {
            "title": "Academics / Learning",
            "image": ""
          },
          {
            "title": "Connecting/socializing with peers, friends, and family",
            "image": ""
          },
          {
            "title": "Pre-Professionnal / Career",
            "image": ""
          }
        ],
        "weight": 1
      },
      {
        "type": "multipleChoice",
        "category": "personal",
        "question": "What factors are most important to you when choosing a college?",
        "options": [
          {
            "title": "Campus culture",
            "image": ""
          },
          {
            "title": "Fits my academic plans",
            "image": ""
          },
          {
            "title": "Close to family / home",
            "image": ""
          },
          {
            "title": "My peers are going",
            "image": ""
          },
          {
            "title": "Meets my financial need / budget",
            "image": ""
          }
        ],
        "weight": 1
      },
      {
        "type": "optionsWithImages",
        "category": "personal",
        "question": "Are you more of an introvert / extrovert?",
        "options": [
          {
            "title": "Introvert",
            "image": ""
          }
        ],
        "weight": 1
      },
      {
        "type": "optionsWithImages",
        "category": "personal",
        "question": "What do you like to do on the weekends?",
        "options": [
          {
            "title": "Hang out with friends / socialize",
            "image": ""
          },
          {
            "title": "Relax / sleep / take it easy",
            "image": ""
          },
          {
            "title": "Adventure / explore the great outdoors",
            "image": ""
          },
          {
            "title": "Sports / Games",
            "image": ""
          }
        ],
        "weight": 1
      },
      {
        "type": "multipleChoice",
        "category": "personal",
        "question": "What are your favorite hobbies / interests (outside of academics)?",
        "options": [
          {
            "title": "Watching shows / movies",
            "image": ""
          },
          {
            "title": "Video games / board games / puzzles",
            "image": ""
          },
          {
            "title": "Reading",
            "image": ""
          },
          {
            "title": "Watching / Playing sports",
            "image": ""
          },
          {
            "title": "Working / Volunteering / Mentoring",
            "image": ""
          },
          {
            "title": "Being Active",
            "image": ""
          },
          {
            "title": "Music / Arts + Crafts",
            "image": ""
          }
        ],
        "weight": 1
      },
      {
        "type": "optionsWithImages",
        "category": "personal",
        "question": "Would you prefer a social atmosphere on campus or a more low-key, scenic experience?",
        "options": [
          {
            "title": "Social life wooh!",
            "image": ""
          },
          {
            "title": "Let's take it easy",
            "image": ""
          }
        ],
        "weight": 1
      }
    ]
  }
}

export default function SettingsPage() {
  const [data, setData] = useState(initialData)

  const handleChange = (path: string, value: string) => {
    setData(prevData => {
      const newData = JSON.parse(JSON.stringify(prevData))
      let current = newData
      const keys = path.split('.')
      const lastKey = keys.pop()
      for (let key of keys) {
        if (!current[key]) current[key] = {}
        current = current[key]
      }
      current[lastKey!] = value
      return newData
    })
  }

  const renderEditableField = (label: string, path: string, value: any) => (
    <Box mb={4}>
      <Text fontWeight="bold">{label}</Text>
      {typeof value === 'string' ? (
        value.length > 100 ? (
          <Textarea
            value={value}
            onChange={(e) => handleChange(path, e.target.value)}
            size="sm"
          />
        ) : (
          <Input
            value={value}
            onChange={(e) => handleChange(path, e.target.value)}
            size="sm"
          />
        )
      ) : (
        <Text color="gray.500">Complex data (not editable here)</Text>
      )}
    </Box>
  )

  return (
    <Box p={5}>
      <VStack spacing={5} align="stretch">
        <Heading>University Data Editor</Heading>
        
        <Accordion allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Basic Information
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {renderEditableField("ID", "id", data.id)}
              {renderEditableField("Domain", "domain", data.domain)}
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  University Details
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {renderEditableField("Name", "SDUI.university.name", data.SDUI.university.name)}
              {renderEditableField("Description", "SDUI.university.description", data.SDUI.university.description)}
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Configuration
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <ConfigEditor />
              {renderEditableField("App Version", "configs.appVersion", data.configs.appVersion)}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <SimpleGrid columns={2} spacing={5}>
          <Button colorScheme="blue">Save Changes</Button>
          <Button variant="outline">Reset</Button>
        </SimpleGrid>
      </VStack>
    </Box>
  )
}