import { Icon } from "@chakra-ui/react";
import { MdHome, MdLock, MdMessage, MdPersonAdd, MdSettings } from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import EngagementPage from "views/admin/default/engagement";
import InvitesPage from "views/admin/default/invites";
import MentorshipCentered from "views/admin/default/mentorship";
import SettingsPage from "views/admin/settings";

// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: "Main Dashboard",
    protected: true,
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Mentorship",
    layout: "/admin",
    protected: true,
    path: "/mentorship",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: MentorshipCentered,
  },
  {
    name: "Invites",
    layout: "/admin",
    protected: true,
    path: "/invites",
    superadmin: true,
    icon: <Icon as={MdPersonAdd} width="20px" height="20px" color="inherit" />,
    component: InvitesPage,
  },
  {
    name: "Engagement",
    layout: "/admin",
    protected: true,
    path: "/engagement",
    superadmin: true,
    icon: <Icon as={MdMessage} width="20px" height="20px" color="inherit" />,
    component: EngagementPage,
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "/settings",
    protected: true,
    superadmin: true,
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    component: SettingsPage,
  },
];

export default routes;
