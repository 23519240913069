'use client'

import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  List,
  ListItem,
  Td,
  Table,
  WrapItem,
  Tr,
  Tbody,
  Text,
  VStack,
  Thead,
  Th,
  Select,
  Flex,
} from '@chakra-ui/react'
import Card from 'components/card/Card';
import { Badge } from '@chakra-ui/react';
import { CodeQueryVariables, useGetCodesByTenant } from 'hooks/useGetCodesByTenant';
import { useCopyToClipboard } from 'usehooks-ts';
import { useBulkCreateCode } from 'hooks/useBulkCreateCode';
import { Code } from 'types/graphql-types';
import { useAuth } from 'contexts/AuthContext';

interface CodeInput {
  tenantId?: string;
  forceConnectionWith?: string;
  email?: string;
  count: number;
  
}

export default function InvitesPage() {
  const [input, setInput] = useState<CodeInput>({ count: 1 })
  const { user } = useAuth()
  const [generatedCodes, setGeneratedCodes] = useState<Code[]>([])
  const [isLoading, setIsLoading] = useState(false)
  
  const { bulkCreateCode, loading: bulkCreateCodeLoading } = useBulkCreateCode();
  const [copiedText, copy] = useCopyToClipboard()
  const [filters, setFilters] = useState<CodeQueryVariables>({})
  const { data: codesData, refetch: refetchCodes } = useGetCodesByTenant({
    ...filters,
    used: filters.used ? true : false,
    tenantId: user?.tenantId
  });

  useEffect(() => {
    console.log('filters', filters)
    refetchCodes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, bulkCreateCodeLoading])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setInput(prev => ({ ...prev, [name]: name === 'count' ? parseInt(value) : value }))
  }

  const handleCopy = (text: string) => () => {
    copy(text)
      .then(() => {
        console.log('Copied!', { text })
      })
      .catch(error => {
        console.error('Failed to copy!', error)
      })
  }

  const handleFilterChange = (key: string, value: string) => {
    setFilters(prev => ({ ...prev, [key]: value }))
  }

  const generateCodes = async () => {
    setIsLoading(true)
    try {
      // This is a mock API call. Replace this with your actual API endpoint.
      const response = await bulkCreateCode({ 
        variables: {
          bulkCreateCodeInput: {
            tenantId: user?.tenantId,
            forceConnectionWith: input.forceConnectionWith,
            email: input.email,
            amount: input.count
          }
        }
      })
      setGeneratedCodes(response.data.bulkCreateCode)
    } catch (error) {
      console.error('Error generating codes:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box maxWidth="2xl" margin="auto" pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card py="15px">
        <Box>
          <Heading size="lg">6-Digit Code Generator</Heading>
          <Text>Generate random 6-digit codes based on your input.</Text>
        </Box>
        <Box>
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel htmlFor="forceConnectionWith">Force Connection With</FormLabel>
              <Input id="forceConnectionWith" name="forceConnectionWith" onChange={handleInputChange} />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input id="email" name="email" type="email" onChange={handleInputChange} />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="count">Number of Codes</FormLabel>
              <FormHelperText>The number of codes you want to generate.</FormHelperText>
              <FormErrorMessage>Number of codes is required.</FormErrorMessage>
              <Input id="count" name="count" type="number" min={1} value={input.count} onChange={handleInputChange} isInvalid={!Boolean(input.count)} />
            </FormControl>
          </VStack>
        </Box>
        <Box>
          <Button onClick={generateCodes} isLoading={isLoading} loadingText="Generating...">
            Generate Codes
          </Button>
        </Box>
        {generatedCodes.length > 0 && (
          <Box>
            <Heading size="md" marginBottom={2}>Generated Codes:</Heading>
            <List spacing={1}>
              {generatedCodes?.map((code, index) => (
                <ListItem key={index}>{code.code}</ListItem>
              ))}
            </List>
          </Box>
        )}
      </Card>
      <Card py="15px">
        <Box maxWidth="1200px" margin="auto" padding={4}>
          <VStack spacing={4} align="stretch">
          <Heading size="lg">Code List</Heading>
          <Flex direction={["column", "row"]} gap={4}>
          <Input
            placeholder="Filter by email"
            value={filters.email}
            onChange={(e) => handleFilterChange('email', e.target.value)}
          />
          <Input
            placeholder="Filter by code"
            value={filters.code}
            onChange={(e) => handleFilterChange('code', e.target.value)}
          />
          <Select
            placeholder="Filter by used status"
            value={filters.used ? 'true' : 'false'}
            onChange={(e) => handleFilterChange('used', e.target.value)}
          >
            <option value="">All</option>
            <option value="true">Used</option>
            <option value="false">Unused</option>
          </Select>
          <Select
            placeholder="Filter by forced with"
            value={filters.forcedWith}
            onChange={(e) => handleFilterChange('forcedWith', e.target.value)}
          >
            <option value="">All</option>
            <option value="null">Not Forced</option>
          </Select>
        </Flex>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Code</Th>
              <Th>Email</Th>
              <Th>Used</Th>
              <Th>Forced With</Th>
            </Tr>
          </Thead>
          <Tbody>
            {codesData?.searchCodes?.data?.map((code, index) => (
              <Tr key={index}>
                <Td>
                  <WrapItem>
                    <Button 
                      colorScheme={copiedText === code.code ? 'green' : 'gray'} 
                      onClick={handleCopy(code.code)}
                    >
                      {copiedText === code.code ? 'Copied!' : code.code}
                    </Button>
                  </WrapItem>
                </Td>
                <Td><Box width="100px" textOverflow="ellipsis" overflow="hidden" overflowWrap="break-word"><Text className='whitespace-nowrap text-overflow-ellipsis'>{code?.email || 'N/A'}</Text></Box></Td>
                <Td>
                  <Badge colorScheme={code?.used ? 'red' : 'green'}>
                    {code?.used ? 'Used' : 'Unused'}
                  </Badge>
                </Td>
                <Td><Text>{code?.forceConnectionWith || 'Not Forced'}</Text></Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        </VStack>
        </Box>
      </Card>
    </Box>
  )
}
