"use client"

import { useState, useEffect } from 'react'
import { motion, AnimatePresence, AnimatePresenceProps } from 'framer-motion'
import { Loader2 } from 'lucide-react'

interface LoadingOverlayProps {
  isLoading: boolean
  message?: string
}

interface NewAnimatePresenceProps extends Omit<AnimatePresenceProps, "children"> {
  children: React.ReactNode;
}

const NewAnimatePresence: React.FC<NewAnimatePresenceProps> = AnimatePresence;

export default function LoadingOverlay({ isLoading, message = 'Loading...' }: LoadingOverlayProps) {
  const [show, setShow] = useState(false)
  
  useEffect(() => {
    if (isLoading) {
      setShow(true)
    } else {
      const timer = setTimeout(() => setShow(false), 300)
      return () => clearTimeout(timer)
    }
  }, [isLoading])

  return (
    <NewAnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-background/80 backdrop-blur-sm"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="flex flex-col items-center space-y-4 text-center">
            <Loader2 className="h-10 w-10 animate-spin text-primary" />
            <p className="text-lg font-medium text-foreground">{message}</p>
          </div>
        </motion.div>
      )}
    </NewAnimatePresence>
  )
}