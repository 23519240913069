import { QUERY_GET_STUDENTS } from "graphql/mentorship.graphql";
import { AccountDto } from "types/graphql-types";
import { useTenantQuery } from "./useTenantQuery";

interface IStudentsData {
  data: { getStudents: AccountDto[] };
  loading: boolean;
  refetch: () => void;
}

export const useStudentsData = (): IStudentsData => {
  return useTenantQuery(QUERY_GET_STUDENTS);
};