import { QueryResult, useQuery } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";
import { PAST_CALLS_QUERY } from "graphql/data.graphql";
import { useEffect, useState } from "react";

interface PastCallsData {
  myCalls: {
    past: any[];
  };
}

interface PastCallsResponse {
  loading: boolean;
  error: Error | undefined;
  calls: any[];
  refetch: QueryResult<PastCallsData, PastCallsVars>["refetch"];
}

interface PastCallsVars {
  accountId: string;
}

export const usePastCalls = (accountId: string): PastCallsResponse => {
  const [pastCalls, setPastCalls] = useState([]);
  const { user } = useAuth();

  const { loading, error, data, refetch } = useQuery<
    PastCallsData,
    PastCallsVars
  >(PAST_CALLS_QUERY, {
    skip: !accountId,
    variables: { accountId },
    context: {
      headers: {
        "x-tenant-id": user?.tenantId,
      },
    },
  });

  useEffect(() => {
    setPastCalls([]);
    refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  useEffect(() => {
    if (data?.myCalls?.past?.length > 0) {
      setPastCalls([...data.myCalls.past]);
    } else {
      setPastCalls([]);
    }
  }, [data, loading, error, accountId]);

  return { loading, error, calls: pastCalls, refetch };
};
