import { useContext, createContext, useState } from "react";
import LoadingOverlay from "components/LoadingOverlay";
interface ActivityIndicatorContextType {
  setIsLoading: (isLoading: boolean) => void;
  isLoading: boolean;
}

const ActivityIndicatorContext = createContext<ActivityIndicatorContextType | undefined>(undefined);

export const ActivityIndicatorProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ActivityIndicatorContext.Provider value={{ setIsLoading, isLoading }}>
      <LoadingOverlay isLoading={isLoading} />
      {children}
    </ActivityIndicatorContext.Provider>
  );
};

export const useActivityIndicator = (): ActivityIndicatorContextType => {
  const context = useContext(ActivityIndicatorContext);

  if (!context) {
    throw new Error("useActivityIndicator must be used within an ActivityIndicatorProvider");
  }

  return context;
};
