import { useQuery, useMutation, DocumentNode, QueryHookOptions, MutationHookOptions } from "@apollo/client";
import { useAuth } from "contexts/AuthContext";

export const useTenantQuery = <TData, TVariables>(
  query: DocumentNode,
  options?: Omit<QueryHookOptions<TData, TVariables>, 'context'>
) => {
  const { user } = useAuth();
  const tenantId = localStorage.getItem("tenantId");

  return useQuery<TData, TVariables>(query, {
    ...options,
    skip: !user?.tenantId || options?.skip || tenantId === null,
    context: {
      headers: {
        "x-tenant-id": user?.tenantId ?? tenantId,
      },
    },
    fetchPolicy: options?.fetchPolicy || "no-cache",
  });
};

export const useTenantMutation = <TData, TVariables>(
  query: DocumentNode,
  options?: Omit<MutationHookOptions<TData, TVariables>, 'context'>
) => {
  const { user } = useAuth();
  const tenantId = localStorage.getItem("tenantId");

  return useMutation<TData, TVariables>(query, {
    ...options,
    context: {
      headers: {
        "x-tenant-id": user?.tenantId ?? tenantId,
      },
    },
    fetchPolicy: options?.fetchPolicy || "no-cache",
  });
};