import { Box, Icon, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { ProfileIcon } from "components/icons/Icons";
import { useActivityIndicator } from "hooks/useActivityIndicator";
import { MdAddTask, MdAvTimer } from "react-icons/md";
import StudendsTable from "views/admin/default/components/StudentsTable";
import MentorsTable from "./components/MentorsTable";
import { useStudentsAnalyticsData } from "hooks/useStudentsAnalyticsData";
import { useMentorsAnalyticsData } from "hooks/useMentorsAnalyticsData";
import { useTenantAnalyicsData } from "hooks/useTenantAnalyicsData";

// Utility function to convert milliseconds to minutes
const convertMillisecondsToMinutes = (milliseconds: number) => {
  return Math.floor(milliseconds / 60000);
};

export default function UserReports() {
  const { setIsLoading } = useActivityIndicator()
  const { data: stdntData, loading: studentsLoading } = useStudentsAnalyticsData()
  const { data: mentorData, loading: mentorsLoading } = useMentorsAnalyticsData()
  const { data: tenantData, loading: tenantLoading } = useTenantAnalyicsData()
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  if(tenantLoading || studentsLoading || mentorsLoading) {
    setIsLoading(true)
    return <>Aquiring data...</>
  } else {
    setIsLoading(false)
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
              icon={<Icon w="28px" h="28px" as={ProfileIcon} color="white" />}
            />
          }
          name="Onboarded Prospective Students (All)"
          value={tenantData?.tenantsAnalytics?.totalStudentsOnboarded}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
              icon={<Icon w="28px" h="28px" as={MdAddTask} color="white" />}
            />
          }
          name="Total Done Calls"
          value={tenantData?.tenantsAnalytics?.totalDoneCalls}
        />

        {/* <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdOutlineMessage}
                  color={brandColor}
                />
              }
            />
          }
          name="Total number of messages sent"
          value={tenantData?.tenantsAnalytics?.nMessages}
        /> */}
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdAvTimer} color={brandColor} />
              }
            />
          }
          name="Average Calls Duration"
          value={`${convertMillisecondsToMinutes(
            tenantData?.tenantsAnalytics?.averageCallsDuration
          )} minutes`}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
              icon={<Icon w="28px" h="28px" as={ProfileIcon} color="white" />}
            />
          }
          name="Active Users (last 14 days)"
          value={tenantData?.tenantsAnalytics?.totalActiveUsersLast14Days}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
              icon={<Icon w="28px" h="28px" as={MdAddTask} color="white" />}
            />
          }
          name="Total Upcoming Calls"
          value={tenantData?.tenantsAnalytics?.totalUpcomingCalls}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdAvTimer} color={brandColor} />
              }
            />
          }
          name="Total time spent on calls"
          value={`${convertMillisecondsToMinutes(
            tenantData?.tenantsAnalytics?.totalCallsDuration
          )} minutes`}
        />
      </SimpleGrid>

      {/* <CoPilotInsights prompt={prompt} /> */}

      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        {stdntData?.studentsAnalytics ? (
          <StudendsTable tableData={stdntData?.studentsAnalytics} />
        ) : null}

        {mentorData?.mentorsAnalytics ? (
          <MentorsTable tableData={mentorData?.mentorsAnalytics} />
        ) : null}
      </SimpleGrid>
    </Box>
  );
}
